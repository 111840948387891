import { priceFormatter } from "./PriceFormatter";

export const getEquity = (propertyToSellValue, outstandingMortgageAmount) => {
  const equity = propertyToSellValue - outstandingMortgageAmount;
  //return equity >= 0 ? equity : null;
  return equity;
};

export const getEquityAlert = (
  propertyToSellValue,
  outstandingMortgageAmount
) => {
  const equity = propertyToSellValue - outstandingMortgageAmount;
  if (propertyToSellValue && outstandingMortgageAmount)
    return [
      equity >= 0 ? "info" : "danger",
      <span>
        Your calculated equity is{" "}
        <span className="whitespace-nowrap font-bold">
          {priceFormatter(equity)}
        </span>
      </span>,
    ];
};

export const getEquityDepositAlert = (
  propertyToSellValue,
  outstandingMortgageAmount
) => {
  const equity = propertyToSellValue - outstandingMortgageAmount;
  return propertyToSellValue && outstandingMortgageAmount && equity >= 1
    ? [
        "info",
        <span>
          We have calculated your deposit at{" "}
          <span className="font-bold">{priceFormatter(equity)}</span>. This will
          be the equity from your property sale. You can amend this figure
        </span>,
      ]
    : null;
};
