import LabelCheckMark from "./LabelCheckMark";
import Tooltip from "../Tooltip";
import Alert from "../Alert";
//import AppContext from "../../Context/AppContext";

function SelectMenu({
  name,
  label,
  labelCheckMark = true,
  options,
  hiddenOptions = [],
  db,
  help,
  value,
  onChange,
  error,
}) {
  const selectedValue = options.find(
    (option) => value === option.value.toString()
  );

  const labelCheckMarkValue = value && !error ? true : false;

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          {labelCheckMark && <LabelCheckMark value={labelCheckMarkValue} />}

          <label
            htmlFor={name}
            className="text-md block font-medium text-black dark:text-white"
          >
            {label}
          </label>
        </div>
        {help && <Tooltip content={help} />}
      </div>

      <select
        id={name}
        name={name}
        value={selectedValue ? selectedValue.value : 0}
        className="text-md mt-1 block w-full rounded-lg border-gray-400 bg-white py-2 pl-3 pr-10 font-bold text-black  focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white"
        onChange={onChange}
      >
        {db && <option value="0">Select</option>}

        {options.map(
          (option) =>
            !hiddenOptions.includes(option.value) && (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.option}
              </option>
            )
        )}
      </select>
      {error && <Alert type="danger" text={error} />}
    </>
  );
}

export default SelectMenu;
