import React from "react";

function AnimatedBackground(props) {
  return (
    <div className="fixed -z-20 flex items-center justify-center w-full min-h-screen">
      <div className="relative w-full max-w-lg">
        {/* <!-- background tints --> */}
        <div className="absolute top-0 -left-4 w-72 h-72 bg-indigo-500/40 dark:bg-indigo-500/60 rounded-full mix-blend-multiply filter blur-xl ring-opacity-50 animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-purple-500/40 dark:bg-purple-500/60 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-sky-500/40 dark:bg-sky-500/60 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
      </div>
    </div>
  );
}

export default AnimatedBackground;
