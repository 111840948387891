import { useContext, useEffect, useState } from "react";
import AppContext from "../Context/AppContext";

function Tooltip({
  content,
  color = "indigo",
  clickable = true,
  disabled = false,
}) {
  const { setModal } = useContext(AppContext);

  const [alignmentClass, setAlignmentClass] = useState("right-full");

  useEffect(() => {}, [alignmentClass]);

  const handleOnMouseEnter = ({ currentTarget }) => {
    const rect = currentTarget.getBoundingClientRect();

    setAlignmentClass(rect.x >= 256 ? "right-full" : "left-full");
  };

  // const iconClasses = () => {
  //   switch (color) {
  //     case "indigo":
  //       return `peer h-6 w-6 text-indigo-500 ${
  //         clickable
  //           ? "hover:text-indigo-600 dark:hover:text-indigo-400 cursor-pointer"
  //           : ""
  //       }`;
  //     case "white":
  //       return "peer h-6 w-6 text-white";
  //   }
  // };

  return (
    <>
      {!disabled ? (
        <div className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`peer h-6 w-6 text-indigo-500 ${
              clickable
                ? "cursor-pointer hover:text-indigo-600 dark:hover:text-indigo-400"
                : ""
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
            onMouseEnter={handleOnMouseEnter}
            onClick={() =>
              clickable && setModal({ modalOpen: true, modalContent: content })
            }
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>

          {!clickable ? (
            <span
              className={`absolute -top-4 ${alignmentClass} -z-10 w-60 rounded bg-black bg-opacity-75 p-4 text-sm text-white opacity-0 transition-all peer-hover:z-10 peer-hover:opacity-100 dark:bg-white dark:text-black`}
            >
              {content}
            </span>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default Tooltip;
