import React from "react";
import NumberFormat from "react-number-format";

export function priceFormatter(value, decimalScale = 0) {
  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"£"}
      decimalScale={decimalScale}
      // fixedDecimalScale={true}
    />
  );
}
