/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MoonIcon, SunIcon, DesktopComputerIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ButtonThemeSelect(props) {
  const handleSelection = (option) => {
    switch (option) {
      case "light":
        localStorage.theme = "light";
        break;
      case "dark":
        localStorage.theme = "dark";
        break;
      default:
        localStorage.removeItem("theme");
        break;
    }
    setTheme();
  };

  const setTheme = () => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className=" ml-4 h-10">
        {localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) ? (
          <MoonIcon
            className="h-6 w-6 text-gray-500 hover:text-gray-600 dark:hover:text-gray-400"
            aria-hidden="true"
          />
        ) : (
          <SunIcon
            className="h-6 w-6 text-gray-500 hover:text-gray-600 dark:hover:text-gray-400"
            aria-hidden="true"
          />
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-4 w-56 origin-top-right rounded-md bg-white shadow-lg shadow-purple-500/25 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:shadow-purple-500/50">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-black dark:bg-gray-900 dark:text-white"
                      : "text-black dark:text-white",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                  onClick={() => handleSelection("light")}
                >
                  <SunIcon
                    className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-400"
                    aria-hidden="true"
                  />
                  Light
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-black dark:bg-gray-900 dark:text-white"
                      : "text-black dark:text-white",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                  onClick={() => handleSelection("dark")}
                >
                  <MoonIcon
                    className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-400"
                    aria-hidden="true"
                  />
                  Dark
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-black dark:bg-gray-900 dark:text-white"
                      : "text-black dark:text-white",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                  onClick={() => handleSelection("system")}
                >
                  <DesktopComputerIcon
                    className="mr-3 h-5 w-5 text-gray-500 group-hover:text-gray-600 dark:group-hover:text-gray-400"
                    aria-hidden="true"
                  />
                  System
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ButtonThemeSelect;
