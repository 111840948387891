import React, { Fragment } from "react";
import CountUp from "react-countup";
import Tooltip from "../Tooltip";
import { priceFormatter } from "../../utils/PriceFormatter";
import { percentageFormatter } from "../../utils/percentageFormatter";

function ResultsBadge({
  size,
  format,
  colorScheme,
  result,
  label,
  help,
  last,
}) {
  // const [previousResult, setPreviousResult] = useState(null);

  // useEffect(() => {
  //   setPreviousResult(result);
  // }, [result]);
  const getColorScheme = () => {
    switch (colorScheme) {
      case 0:
        return "from-rose-500 via-pink-500 to-fuchsia-500";
      case 1:
        return "from-fuchsia-500 via-violet-500 to-indigo-500";
      case 2:
        return "from-blue-500 via-sky-500 to-cyan-500";
      case 3:
        return "from-teal-500 via-emerald-500 to-green-500";
      default:
        return "border border-gray-400 from-white dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900";
    }
  };

  const padding = size === "large" ? "px-6 py-3" : "px-3 py-3";

  const textSize = size === "large" ? "text-xl" : "text-md";

  const textColor =
    colorScheme === null ? "text-black dark:text-white" : "text-white";

  const margin = last ? "mb-0" : "mb-4 sm:mb-2 md:mb-4 lg:mb-2";

  // const formatResult = (format, result) => {
  //   if (format === "price") return result ? priceFormatter(result) : "£0";

  //   if (format === "percentage")
  //     return result ? percentageFormatter(result) : "0.00%";
  // };

  // const percentageFormatter = (value) => {
  //   if (format === "percentage") return value.toFixed(2) + "%";
  // };

  return (
    <Fragment>
      <div
        className={`flex flex-col items-center gap-0 sm:flex-row sm:gap-4 md:flex-col md:gap-0 lg:flex-row lg:gap-4 ${margin} `}
      >
        <div className="flex justify-center sm:w-full sm:justify-between md:w-auto md:justify-center lg:w-full lg:justify-between">
          <p className="text-md text-center font-bold text-black dark:text-white sm:text-left md:text-center lg:text-left">
            {label}
          </p>

          {help && (
            // <div className="pl-4 sm:p-0 md:pl-4 lg:p-0">
            <div className="pl-4 ">
              <Tooltip content={help} color="indigo" />
            </div>
          )}
        </div>

        <span
          className={`inline-flex items-center bg-gradient-to-tl ${getColorScheme()} ${padding} rounded-full font-bold ${textColor} ${textSize} leading-none`}
        >
          {/* {formatResult(format, result)} */}
          <CountUp
            end={result}
            duration={0.5}
            decimals={format === "price" ? 0 : 2}
            separator={format === "price" ? "," : ""}
            prefix={format === "price" ? "£" : ""}
            preserveValue={true}
            formattingFn={format === "percentage" && percentageFormatter}
          />
        </span>
      </div>
    </Fragment>
  );
}

export default ResultsBadge;
