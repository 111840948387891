import { priceFormatter } from "../utils/PriceFormatter";
import { percentageFormatter } from "../utils/percentageFormatter";

function RepresentativeExample({ product }) {
  const monthsToYears = (months) => {
    // Get the number of years.
    const years = Math.floor(months / 12);

    // Get the remainder in months.
    const monthsRemainder = months % 12;

    const yearString = years === 1 ? "year" : "years";

    const monthString = monthsRemainder === 1 ? "month" : "months";

    //Return the years and months in a string.
    return years && monthsRemainder
      ? `${years} ${yearString} and ${monthsRemainder} ${monthString}`
      : years && !monthsRemainder
      ? `${years} ${yearString}`
      : `${monthsRemainder} ${monthString}`;
  };

  // const getInitialRateType = () => {
  //   const rateType = Object.keys(product.InitialRateType).find(
  //     (item) => product.InitialRateType[item] === true
  //   );

  //   return rateType === "Fixed"
  //     ? "fixed"
  //     : rateType === "Tracker_BBR"
  //     ? "tracker"
  //     : "variable";
  // };

  const rateType = Object.keys(product.initialRateType).find(
    (item) => product.initialRateType[item] === true
  );

  const getStep1Details = () => {
    const rateStep = product.graphRateSteps[0];
    return (
      <>
        {product.graphRateStepsCount > 1 ? ", initially" : null} on a{" "}
        <span className="font-bold">{rateStep.rateType.toLowerCase()}</span>{" "}
        rate for{" "}
        <span className="font-bold">{monthsToYears(rateStep.monthCount)}</span>{" "}
        at{" "}
        <span className="font-bold">{percentageFormatter(rateStep.rate)}</span>
      </>
    );
  };

  const getStep2Details = () => {
    const rateStep = product.graphRateSteps[1];
    return (
      <>
        <span className="font-bold">{rateStep.rateType.toLowerCase()}</span>{" "}
        rate{" "}
        {product.graphRateStepsCount === 3
          ? rateStep.rate < product.graphRateSteps[2].rate
            ? "(discounted)"
            : null
          : null}{" "}
        for {product.graphRateStepsCount === 2 ? "the remaining" : null}{" "}
        <span className="font-bold">{monthsToYears(rateStep.monthCount)}</span>{" "}
        at{" "}
        <span className="font-bold">{percentageFormatter(rateStep.rate)}</span>
      </>
    );
  };

  const getStep3Details = () => {
    const rateStep = product.graphRateSteps[2];
    return (
      <>
        <span className="font-bold">{rateStep.rateType.toLowerCase()}</span>{" "}
        rate{" "}
        {product.graphRateStepsCount === 3
          ? rateStep.rate < product.graphRateSteps[1].rate
            ? "(discounted)"
            : null
          : null}{" "}
        for the remaining{" "}
        <span className="font-bold">{monthsToYears(rateStep.monthCount)}</span>{" "}
        at{" "}
        <span className="font-bold">{percentageFormatter(rateStep.rate)}</span>
      </>
    );
  };

  const getStep1Payments = () => {
    const rateStep = product.graphRateSteps[0];
    return (
      <>
        <span className="font-bold">{rateStep.monthCount}</span> payments of{" "}
        <span className="font-bold">
          {priceFormatter(product.initialMonthlyPayment, 2)}
        </span>
      </>
    );
  };

  const getStep2Payments = () => {
    const rateStep = product.graphRateSteps[1];
    return (
      <>
        <span className="font-bold">{rateStep.monthCount}</span> payments of{" "}
        <span className="font-bold">
          {product.graphRateStepsCount === 3
            ? rateStep.rate < product.graphRateSteps[2].rate
              ? priceFormatter(
                  product.finalMonthlyPayment -
                    product.difference / rateStep.monthCount,
                  2
                )
              : priceFormatter(product.finalMonthlyPayment, 2)
            : product.graphRateStepsCount === 2
            ? priceFormatter(product.finalMonthlyPayment, 2)
            : null}
        </span>
      </>
    );
  };

  const getStep3Payments = () => {
    const rateStep = product.graphRateSteps[2];
    return (
      <>
        <span className="font-bold">{rateStep.monthCount}</span> payments of{" "}
        <span className="font-bold">
          {rateStep.rate < product.graphRateSteps[1].rate
            ? priceFormatter(
                product.finalMonthlyPayment -
                  product.difference / rateStep.monthCount,
                2
              )
            : priceFormatter(product.finalMonthlyPayment, 2)}
        </span>
      </>
    );
  };

  return (
    <div className="p2 col-span-1 p-2 text-black dark:text-white sm:col-span-3">
      <p className="text-xs">
        <span className="font-bold underline">Representative example:</span> A
        mortgage of{" "}
        <span className="font-bold">{priceFormatter(product.loanAmount)}</span>{" "}
        payable over <span className="font-bold">{product.term}</span> years
        {getStep1Details()}
        {/* rate for{" "}
        <span className="font-bold">
          {monthsToYears(product.rateControlPeriod)}
        </span>{" "}
        at{" "}
        <span className="font-bold">
          {percentageFormatter(product.initialRate)}
        </span> */}
        {product.graphRateStepsCount > 1 && (
          <span> and then on a {getStep2Details()}</span>
        )}
        {product.graphRateStepsCount === 3 && (
          <span> and then on a {getStep3Details()}</span>
        )}
        {/*<span className="font-bold">variable</span> rate of{" "}
        <span className="font-bold">
          {percentageFormatter(product.finalRate)}
        </span>{" "}
        for the remaining{" "}
        <span className="font-bold">
          {monthsToYears(product.term * 12 - product.rateControlPeriod)}
        </span>*/}
        . This would require {getStep1Payments()}
        {/*<span className="font-bold">{product.rateControlPeriod}</span>{" "}
          payments of 
          <span className="font-bold">
            {priceFormatter(product.initialMonthlyPayment, 2)}
          </span>{" "} */}
        {product.graphRateStepsCount > 1 && (
          <span>
            {product.graphRateStepsCount === 3 ? "," : " and"}{" "}
            {getStep2Payments()}
          </span>
        )}
        {product.graphRateStepsCount === 3 && (
          <span> and {getStep3Payments()}</span>
        )}
        {/* <span className="font-bold">
            {product.term * 12 - product.rateControlPeriod}
          </span>{" "}
          payments of{" "}
          <span className="font-bold">
            {priceFormatter(product.finalMonthlyPayment, 2)}
          </span> */}
        . The total amount payable would be{" "}
        <span className="font-bold">
          {priceFormatter(product.totalAmountPayableOverTerm, 2)}
        </span>{" "}
        made up of the loan amount plus interest{" "}
        <span className="font-bold">
          (
          {priceFormatter(
            product.totalAmountPayableOverTerm -
              product.loanAmount -
              product.totalFees -
              product.finalFee +
              product.cashback,
            2
          )}
          )
        </span>
        , fees{" "}
        <span className="font-bold">
          ({priceFormatter(product.totalFees, 2)})
        </span>{" "}
        and a final fee{" "}
        <span className="font-bold">
          ({priceFormatter(product.finalFee, 2)})
        </span>
        . The overall cost for comparison is{" "}
        <span className="font-bold">{percentageFormatter(product.APRC)}</span>{" "}
        APRC representative.
      </p>
    </div>
  );
}

export default RepresentativeExample;
