import { priceFormatter } from "./PriceFormatter";

export const getMinDepositAmount = (sharePrice) => {
  return (sharePrice * 5) / 100;
};

export const getSharedOwnershipDepositAlert = (alertType, sharePrice) => {
  return [
    alertType,
    <span>
      A minimum of <span className="font-bold">5%</span> deposit is required for
      the Shared Ownership scheme.{" "}
      <span className="font-bold">
        {priceFormatter(getMinDepositAmount(sharePrice))}
      </span>
    </span>,
  ];
};
