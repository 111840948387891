import Tooltip from "./Tooltip";

const ButtonGroup = ({
  name,
  label,
  items,
  help,
  selectedItem,
  onItemsSelect,
}) => {
  const getClasses = (item) => {
    return item._id === selectedItem
      ? "mr-2 mb-2 inline-flex items-center whitespace-nowrap rounded-full bg-indigo-500 px-4 py-1 text-xs font-semibold leading-none text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-wait disabled:bg-gray-200 disabled:hover:bg-gray-200 dark:hover:bg-indigo-400 dark:disabled:bg-gray-800 dark:disabled:hover:bg-gray-800 sm:text-sm"
      : "mr-2 mb-2 inline-flex items-center whitespace-nowrap rounded-full bg-gray-100 px-4 py-1 text-xs font-semibold leading-none text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 disabled:cursor-wait disabled:bg-gray-200 disabled:hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:disabled:bg-gray-800 dark:disabled:hover:bg-gray-800 sm:text-sm";
  };

  return (
    <div className="mb-6 flex-wrap items-center justify-start">
      <div className="flex items-start gap-4">
        <p className="mb-1 whitespace-nowrap text-black dark:text-white">
          {label}
        </p>
        {help && <Tooltip content={help} />}
      </div>

      {items.map((item) => (
        <button
          key={item._id}
          type="button"
          className={getClasses(item)}
          onClick={() => onItemsSelect(name, item)}
          {...(item._id === selectedItem && {
            "aria-selected": "true",
          })}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
