import moment from "moment";

export const isToday = (value) => {
  const isToday = moment(value).day() === moment().day() ? true : false;
  return isToday;
};

export const isTomorrow = (value) => {
  const isTomorrow =
    moment(value).day() === moment().add(1, "days").day() ? true : false;
  return isTomorrow;
};

export const renderDay = (value) => {
  const day = isToday(value)
    ? "Today"
    : isTomorrow(value)
    ? "Tomorrow"
    : moment(value).format("dddd");

  return day;
};

export const renderDate = (value) => {
  const day = moment(value).format("Do MMMM, YYYY");
  return day;
};

export const renderTime = (value) => {
  let time;
  switch (parseInt(value)) {
    case 9:
      time = "9am - 12pm";
      break;
    case 12:
      time = "12pm - 3pm";
      break;
    case 15:
      time = "3pm - 6pm";
      break;
    case 18:
      time = "6pm - 9pm";
      break;
    default:
      time = "Daytime";
  }
  return time;
};
