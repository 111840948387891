import React, { Fragment } from "react";

const getDefaultClasses = () => {
  return "relative inline-flex items-center leading-none rounded-full uppercase whitespace-nowrap disabled:text-gray-400 disabled:bg-gray-200 disabled:dark:bg-gray-800 disabled:dark:text-gray-600 disabled:cursor-not-allowed";
};

const getBackgroundClasses = (color) => {
  switch (color) {
    case "indigo":
      return "bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:hover:bg-indigo-400";
    case "emerald":
      return "bg-emerald-500 text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 dark:hover:bg-emerald-400";
    case "sky":
      return "bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 dark:hover:bg-sky-400";
    case "white":
      return "border border-gray-600 bg-white text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-400 dark:bg-black dark:text-gray-400 dark:hover:bg-gray-900 dark:focus:ring-gray-400 focus:ring-offset-white dark:focus:ring-offset-black";
    case "plain":
      return "bg-gray-100 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600 dark:focus:ring-offset-black";
    case "plainActive":
      return "bg-indigo-500 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white";
  }
};

const getPingBackgroundClass = (color) => {
  return color === "plain" ? "bg-gray-100 dark:bg-gray-600" : `bg-${color}-500`;
};

const getAnimateClass = (animate) => {
  return animate ? "animate-ping" : "animate-none";
};

export function ButtonDefault({
  color,
  label,
  onClick,
  disabled = false,
  animate = false,
}) {
  return (
    <div className="relative flex">
      <div
        className={`absolute inline-flex h-full w-full ${getAnimateClass(
          animate
        )} rounded-full ${getPingBackgroundClass(color)}`}
      ></div>

      <button
        type="button"
        className={`${getDefaultClasses()} px-3 py-2 text-xs font-semibold sm:text-sm ${getBackgroundClasses(
          color
        )}`}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
}

export function ButtonLarge({
  color,
  label,
  onClick,
  disabled = false,
  animate = false,
}) {
  return (
    <div className="relative flex">
      <div
        className={`absolute inline-flex h-full w-full ${getAnimateClass(
          animate
        )} rounded-full ${getPingBackgroundClass(color)}`}
      ></div>

      <button
        type="button"
        className={`${getDefaultClasses()} px-[25px] py-[10px] text-[0.9rem] font-bold ${getBackgroundClasses(
          color
        )}`}
        onClick={onClick}
        disabled={disabled}
        selected={true}
      >
        {label}
      </button>
    </div>
  );
}

export function ButtonSmall({
  color,
  label,
  onClick,
  disabled = false,
  animate = false,
}) {
  return (
    <div className="relative flex">
      <div
        className={`absolute inline-flex h-full w-full ${getAnimateClass(
          animate
        )} rounded-full ${getPingBackgroundClass(color)}`}
      ></div>

      <button
        type="button"
        className={`${getDefaultClasses()} px-4 py-2 text-xs font-semibold ${getBackgroundClasses(
          color
        )}`}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
}
