import Tooltip from "../Tooltip";
import Alert from "../Alert";

function CheckboxGroup({ name, label, options, help, value, onChange, error }) {
  return (
    <>
      <div className="flex items-start justify-between">
        <label
          htmlFor={name}
          className="text-md block font-medium text-black  dark:text-white"
        >
          {label}
        </label>
        {help && <Tooltip content={help} />}
      </div>

      <fieldset className="mb-1">
        <legend className="sr-only">{label}</legend>

        {options.map((option) => (
          <div key={option._id} className="mt-1 flex items-center">
            <input
              type="checkbox"
              name={name}
              id={`${name}-${option.key}`}
              data-key={option.key}
              value={value[option.key] ? 1 : 0}
              className="h-5 w-5 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
              aria-describedby={`${name}-help`}
              onChange={onChange}
            />

            <div className="ml-4">
              <label
                htmlFor={`${name}-${option.key}`}
                className="text-sm font-medium text-black dark:text-white"
              >
                {option.option}
              </label>
              <span className="sr-only">{option.option}</span>
            </div>
          </div>
        ))}
      </fieldset>
      {error && <Alert type="danger" text={error} />}
    </>
  );
}

export default CheckboxGroup;
