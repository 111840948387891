import React, { Fragment } from "react";

function LogoTHR(props) {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="threshold-mortgage-advice-logo"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="32"
        viewBox="0 0 320 77.6"
        style={{ enableBackground: "new 0 0 320 77.6" }}
        xmlSpace="preserve"
        // className="w-full pr-4"
      >
        <g>
          <g>
            <path
              className="fill-black dark:fill-white"
              d="M82.9,17.3h4.5V7.5h4.9v9.9h6v4h-6v20.3c0,2.2,1.1,2.9,3.1,2.9c1,0,2.1-0.2,2.6-0.4h0.2v4.2
			c-1.1,0.2-2.5,0.4-3.9,0.4c-4,0-6.9-1.6-6.9-6V21.3h-4.5V17.3z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M108.3,21.4h0.1c2.2-3,5.4-4.8,9.9-4.8c6.5,0,10.5,3.6,10.5,10v22H124V27.1c0-4.1-2.7-6.3-7.1-6.3
			c-5,0-8.6,3.5-8.6,8.6v19.2h-4.9V5.4h4.9V21.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M140.3,22.5h0.1c1.9-3,5.1-5.6,9.3-5.6c0.9,0,1.4,0.1,2,0.4v4.7h-0.2c-0.7-0.2-1.2-0.3-2.2-0.3
			c-4.8,0-9.1,3.6-9.1,9.5v17.4h-4.9V17.3h4.9V22.5z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M153.2,33c0-9.1,5.9-16.4,14.8-16.4c9.4,0,14.4,7.3,14.4,17.6h-24.2c0.4,6.3,3.8,11.2,10.1,11.2
			c4.5,0,7.6-2.2,8.6-6.4h4.8c-1.5,6.7-6.3,10.5-13.4,10.5C158.7,49.4,153.2,42.1,153.2,33z M158.3,30.5h18.8
			c-0.3-6.2-3.5-10-9.1-10C162.2,20.4,159,24.8,158.3,30.5z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M185.1,38.4h5c0.7,5.4,4.3,7,8.9,7c5.1,0,7.3-2.2,7.3-5.1c0-3.5-2.4-4.4-8.2-5.6c-6.2-1.3-11.7-2.5-11.7-9.4
			c0-5.2,4-8.6,11.2-8.6c7.7,0,11.6,3.7,12.3,9.8h-5c-0.5-4.1-2.7-5.9-7.4-5.9c-4.5,0-6.3,2-6.3,4.5c0,3.4,3,4.1,8.4,5.1
			c6.3,1.3,11.6,2.7,11.6,9.9c0,6.3-5.1,9.5-12.3,9.5C190.4,49.5,185.6,45.5,185.1,38.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M220.9,21.4h0.1c2.2-3,5.4-4.8,9.9-4.8c6.5,0,10.5,3.6,10.5,10v22h-4.9V27.1c0-4.1-2.7-6.3-7.1-6.3
			c-5,0-8.6,3.5-8.6,8.6v19.2H216V5.4h4.9V21.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M246.2,33c0-9.1,5.8-16.4,15.1-16.4c9.4,0,15.1,7.4,15.1,16.4s-5.7,16.4-15.1,16.4
			C252,49.4,246.2,42.1,246.2,33z M271.4,33c0-6.9-3.4-12.4-10-12.4c-6.7,0-10.1,5.5-10.1,12.4c0,6.9,3.4,12.3,10.1,12.3
			C268,45.3,271.4,39.9,271.4,33z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M281.5,5.4h4.9v43.2h-4.9V5.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M291.5,33.1c0-10.4,6-16.4,13.7-16.4c4.4,0,7.8,2.2,9.8,5.1h0.1V5.4h4.9v43.2h-4.9v-3.8H315
			c-2.4,3-5.3,4.7-9.7,4.7C296.8,49.5,291.5,43.1,291.5,33.1z M315.4,33.1c0-7.3-3.5-12.3-10-12.3c-5.9,0-8.9,5.3-8.9,12.3
			c0,7.1,3,12.3,9,12.3C312.4,45.4,315.4,40.2,315.4,33.1z"
            />
          </g>
          <g>
            <path
              className="fill-black dark:fill-white"
              d="M87.3,57.9H89v1.5h0c0.7-1,1.8-1.8,3.2-1.8c1.3,0,2.4,0.7,2.8,1.9h0c0.7-1.2,2-1.9,3.3-1.9
			c1.9,0,3.3,1.2,3.3,3.5v7.6h-1.7v-7.5c0-1.3-0.9-2.1-2-2.1c-1.4,0-2.6,1.2-2.6,2.9v6.7h-1.7v-7.5c0-1.3-0.8-2.1-1.9-2.1
			c-1.3,0-2.8,1.2-2.8,2.9v6.7h-1.7V57.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M109.5,63.3c0-3.1,2-5.7,5.2-5.7c3.2,0,5.2,2.5,5.2,5.7c0,3.1-2,5.7-5.2,5.7
			C111.5,68.9,109.5,66.4,109.5,63.3z M118.1,63.3c0-2.4-1.2-4.3-3.5-4.3s-3.5,1.9-3.5,4.3c0,2.4,1.2,4.3,3.5,4.3
			S118.1,65.6,118.1,63.3z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M129.5,59.6L129.5,59.6c0.7-1,1.8-1.9,3.3-1.9c0.3,0,0.5,0,0.7,0.1v1.6h-0.1c-0.3-0.1-0.4-0.1-0.8-0.1
			c-1.7,0-3.1,1.3-3.1,3.3v6h-1.7V57.9h1.7V59.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M140.5,57.9h1.6v-3.4h1.7v3.4h2.1v1.4h-2.1v7c0,0.7,0.4,1,1.1,1c0.3,0,0.7-0.1,0.9-0.1h0.1v1.5
			c-0.4,0.1-0.9,0.1-1.4,0.1c-1.4,0-2.4-0.6-2.4-2.1v-7.4h-1.6V57.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M153.5,69.2h1.6c0.2,1.2,1.3,1.6,3,1.6c2,0,3.1-0.9,3.1-2.8v-1.5h0c-0.8,1-1.6,1.7-3.2,1.7
			c-2.8,0-4.7-2.2-4.7-5.3c0-3.4,2.1-5.3,4.6-5.3c1.6,0,2.6,0.6,3.3,1.6h0v-1.4h1.7V68c0,2.8-1.9,4.2-4.7,4.2
			C155.3,72.2,153.7,71.1,153.5,69.2z M161.2,62.8c0-2.4-1-3.8-3.3-3.8c-2,0-3,1.6-3,3.9c0,2.4,1.1,3.9,3.1,3.9
			C160.4,66.8,161.2,65.1,161.2,62.8z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M177.9,67.1L177.9,67.1c-0.7,0.8-1.8,1.7-3.6,1.7c-2,0-3.6-1-3.6-3c0-2.5,2-3.1,4.7-3.5
			c1.5-0.2,2.6-0.4,2.6-1.5c0-1.2-0.9-1.9-2.4-1.9c-1.8,0-2.6,0.8-2.7,2.4h-1.6c0.1-2.1,1.4-3.6,4.3-3.6c2.3,0,4,0.9,4,3.4v5.1
			c0,0.9,0.2,1.4,1.1,1.1h0.1v1.2c-0.2,0.1-0.5,0.2-0.9,0.2C178.7,68.7,178.1,68.2,177.9,67.1z M178,64.6v-1.8
			c-0.6,0.4-1.5,0.6-2.5,0.8c-1.7,0.3-3,0.6-3,2.2c0,1.4,1.1,1.8,2.2,1.8C177,67.5,178,65.9,178,64.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M187.9,69.2h1.6c0.2,1.2,1.3,1.6,3,1.6c2,0,3.1-0.9,3.1-2.8v-1.5h0c-0.8,1-1.6,1.7-3.2,1.7
			c-2.8,0-4.7-2.2-4.7-5.3c0-3.4,2.1-5.3,4.6-5.3c1.6,0,2.6,0.6,3.3,1.6h0v-1.4h1.7V68c0,2.8-1.9,4.2-4.7,4.2
			C189.7,72.2,188.1,71.1,187.9,69.2z M195.7,62.8c0-2.4-1-3.8-3.3-3.8c-2,0-3,1.6-3,3.9c0,2.4,1.1,3.9,3.1,3.9
			C194.9,66.8,195.7,65.1,195.7,62.8z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M205.2,63.3c0-3.1,2-5.7,5.1-5.7c3.2,0,5,2.5,5,6.1h-8.3c0.1,2.2,1.3,3.9,3.5,3.9c1.6,0,2.6-0.7,3-2.2h1.6
			c-0.5,2.3-2.2,3.6-4.6,3.6C207.1,68.9,205.2,66.4,205.2,63.3z M207,62.4h6.5c-0.1-2.1-1.2-3.5-3.1-3.5
			C208.3,58.9,207.2,60.4,207,62.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M239.3,67.1L239.3,67.1c-0.7,0.8-1.8,1.7-3.6,1.7c-2,0-3.6-1-3.6-3c0-2.5,2-3.1,4.7-3.5
			c1.5-0.2,2.6-0.4,2.6-1.5c0-1.2-0.9-1.9-2.4-1.9c-1.8,0-2.6,0.8-2.7,2.4h-1.6c0.1-2.1,1.4-3.6,4.3-3.6c2.3,0,4,0.9,4,3.4v5.1
			c0,0.9,0.2,1.4,1.1,1.1h0.1v1.2c-0.2,0.1-0.5,0.2-0.9,0.2C240.1,68.7,239.5,68.2,239.3,67.1z M239.4,64.6v-1.8
			c-0.6,0.4-1.5,0.6-2.5,0.8c-1.7,0.3-3,0.6-3,2.2c0,1.4,1.1,1.8,2.2,1.8C238.3,67.5,239.4,65.9,239.4,64.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M249,63.3c0-3.6,2.1-5.7,4.7-5.7c1.5,0,2.7,0.8,3.4,1.8h0v-5.6h1.7v14.9h-1.7v-1.3h0c-0.8,1-1.8,1.6-3.3,1.6
			C250.8,68.9,249,66.7,249,63.3z M257.2,63.3c0-2.5-1.2-4.3-3.4-4.3c-2,0-3.1,1.8-3.1,4.3c0,2.4,1,4.2,3.1,4.2
			C256.2,67.5,257.2,65.7,257.2,63.3z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M266.3,57.9h1.8l2.2,6.8c0.3,1,0.7,2.3,0.7,2.3h0c0,0,0.4-1.3,0.7-2.3l2.3-6.8h1.8l-3.9,10.8H270L266.3,57.9z
			"
            />
            <path
              className="fill-black dark:fill-white"
              d="M283.2,53.7h1.7v2.1h-1.7V53.7z M283.2,57.9h1.7v10.8h-1.7V57.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M292.9,63.3c0-3.1,2-5.7,5.2-5.7c2.5,0,4.2,1.5,4.6,3.8H301c-0.2-1.5-1.3-2.4-2.9-2.4c-2.3,0-3.5,1.9-3.5,4.3
			c0,2.4,1.2,4.3,3.5,4.3c1.8,0,2.8-1,2.9-2.7h1.7c-0.2,2.6-1.9,4.1-4.6,4.1C294.9,68.9,292.9,66.4,292.9,63.3z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M309.9,63.3c0-3.1,2-5.7,5.1-5.7c3.2,0,5,2.5,5,6.1h-8.3c0.1,2.2,1.3,3.9,3.5,3.9c1.6,0,2.6-0.7,3-2.2h1.6
			c-0.5,2.3-2.2,3.6-4.6,3.6C311.9,68.9,309.9,66.4,309.9,63.3z M311.7,62.4h6.5c-0.1-2.1-1.2-3.5-3.1-3.5
			C313,58.9,312,60.4,311.7,62.4z"
            />
          </g>
          <g>
            <path
              className="fill-black dark:fill-white"
              d="M46.3,15.2C46.3,15.2,46.3,15.2,46.3,15.2c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2-0.1-0.4-0.2-0.6
			c-0.2-0.3-0.5-0.5-0.9-0.5c-0.1,0-0.1,0-0.2,0c0.2,0.2,0.4,0.5,0.6,0.7h0l-0.5,0.3l0.5-0.3C46,14.7,46.1,14.9,46.3,15.2z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M45.3,14.7c-0.2-0.3-0.4-0.5-0.7-0.8l-2.5,2c0.1,0.1,0.2,0.2,0.3,0.3l0,0c0.1,0.1,0.2,0.3,0.2,0.4l3.2-1.1
			C45.6,15.3,45.5,15,45.3,14.7z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M49,10.4L49,10.4l-3.4,2.7l0,0c0.5,0.1,0.9,0.3,1.1,0.7c0.2,0.3,0.3,0.6,0.3,0.9c0,0.1,0,0.2,0,0.4l0.2-0.1
			l4.2-1.5l0.4-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.5-0.3-0.9-0.7-1.2-1.2c-0.4-0.6-0.5-1.2-0.5-1.8c0-0.1,0-0.2,0-0.2L49,10.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M41.7,15.6l2.5-2c-0.2-0.2-0.5-0.4-0.7-0.6v0c-0.3-0.2-0.6-0.3-0.9-0.5L41,15.1c0.1,0.1,0.2,0.1,0.3,0.2l0,0
			c0,0,0,0,0,0c0,0,0,0,0,0l0,0C41.5,15.4,41.6,15.5,41.7,15.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M46.3,17L46.3,17c-0.1-0.3-0.2-0.7-0.3-1l-3.2,1.1c0,0.1,0.1,0.3,0.1,0.4h0c0,0.1,0.1,0.3,0.1,0.4h3.4
			C46.4,17.7,46.4,17.3,46.3,17z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M46.6,16c0.1,0.3,0.2,0.6,0.3,0.9h0L46.6,17l0.3-0.1c0.1,0.3,0.1,0.6,0.1,1c0.1-0.1,0.2-0.1,0.2-0.2
			c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0-0.1,0-0.2C47.4,16.4,47,16.1,46.6,16z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M43.3,11.4c0.1,0,0.2,0,0.2,0c0.4,0,0.7,0.1,1,0.4L44.3,12l0.2-0.2c0.4,0.3,0.6,0.7,0.6,1.1l0.2-0.1l3.4-2.7
			l0.3-0.2c-0.7,0-1.4-0.3-2-0.8L47,8.9L46.9,9c-0.4-0.3-0.7-0.7-0.9-1.1c-0.1-0.2-0.2-0.4-0.3-0.6l-0.3,0.5L43.3,11.4L43.3,11.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M36.9,11.1c-0.2-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.2,0-0.3,0.1l-0.1-0.3L36,11c-0.2,0.1-0.4,0.2-0.5,0.4
			c-0.1,0.1-0.1,0.2-0.2,0.4c0.3-0.1,0.6-0.2,0.9-0.3l0,0c0.3-0.1,0.6-0.1,0.9-0.2C37,11.2,37,11.1,36.9,11.1z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M43.5,12c-0.2,0-0.3,0-0.5,0.1c0.3,0.1,0.5,0.3,0.8,0.4c0.3,0.2,0.5,0.4,0.8,0.6c0,0,0-0.1,0-0.1
			c0-0.3-0.1-0.6-0.4-0.8v0C43.9,12,43.7,12,43.5,12z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M35,12.5c-0.3,0.1-0.6,0.3-0.9,0.5L34,12.8l0.1,0.2c-0.3,0.2-0.5,0.4-0.7,0.6l2.5,2c0.1-0.1,0.2-0.2,0.3-0.3
			l0,0c0.1-0.1,0.2-0.1,0.3-0.2L35,12.5z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="38.2,14.8 38.2,14.8 38.2,14.9 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M37.3,11.7c-0.3,0.1-0.6,0.1-0.9,0.2l-0.1-0.3l0.1,0.3c-0.3,0.1-0.6,0.2-0.9,0.3l1.6,2.6
			c0.1,0,0.3-0.1,0.4-0.1v0c0.1,0,0.2-0.1,0.4-0.1L37.3,11.7z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="39.4,14.8 39.4,14.8 39.4,14.9 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M38.8,11.6c-0.3,0-0.6,0-0.9,0.1l0.5,2.9c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l0.5-2.9
			C39.4,11.6,39.1,11.6,38.8,11.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M39.7,11.1c0-0.1-0.1-0.2-0.2-0.2c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.1,0.1-0.1,0.2-0.2,0.2
			c0.3,0,0.6,0,0.9,0C39.1,11,39.4,11.1,39.7,11.1z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M37.6,24.3h-6.3v4.6h6.3V24.3z M36.4,27.6h-3.9v-2.1h3.9V27.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M38,30.7c0,0.2,0.3,0.3,0.7,0.3c0.4,0,0.7-0.1,0.7-0.3l-0.1-1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.3-0.4-0.6-0.8-0.6
			c-0.5,0-0.8,0.2-0.8,0.6c0,0.1,0.1,0.3,0.2,0.4L38,30.7z M38.3,30c0.1,0.2,0.2,0.3,0.5,0.3c0.2,0,0.4-0.1,0.5-0.3l0.1,0.7
			c0,0.1-0.3,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.6-0.2L38.3,30z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M46.2,24.3h-6.3v4.6h6.3V24.3z M45,27.6h-3.9v-2.1H45V27.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M46.2,31.1h-6.3v10.3h6.3V31.1z M45,40.2h-3.9v-7.9H45V40.2z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M39.9,53.3h6.3v-8h-6.3V53.3z M41.3,46.8h3.5v5.2h-3.5V46.8z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M41.8,44.4c0,0,0.1-0.1,0.1-0.1v-1.7c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h-5.7c-0.1,0-0.1,0-0.1,0.1
			c0,0-0.1,0.1-0.1,0.1v1.7c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1h5.7C41.7,44.4,41.7,44.4,41.8,44.4z M41.4,44h-5.3v-1.3h5.3V44
			z"
            />
            <rect
              x="36.5"
              y="43.1"
              className="fill-black dark:fill-white"
              width="4.6"
              height="0.6"
            />
            <path
              className="fill-black dark:fill-white"
              d="M28.9,10l3.4,2.7l0,0l0.2,0.1c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.2,0.7-0.4,1-0.4c0.1,0,0.2,0,0.2,0l0,0l-2.1-3.5
			l-0.3-0.5c-0.1,0.2-0.1,0.4-0.3,0.6c-0.2,0.4-0.5,0.8-0.9,1.1l-0.3-0.3L30.7,9c-0.6,0.5-1.3,0.7-2,0.8L28.9,10z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M40.7,11.1c-0.1,0-0.1,0.1-0.1,0.1c0.3,0.1,0.6,0.1,0.9,0.2l0,0c0.3,0.1,0.6,0.2,0.9,0.3
			c0-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.3-0.3-0.5-0.4l0,0c-0.1,0-0.2-0.1-0.3-0.1C41,10.9,40.8,11,40.7,11.1z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M37.6,31.1h-6.3v10.3h6.3V31.1z M36.4,40.2h-3.9v-7.9h3.9V40.2z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M33,13c0,0,0,0.1,0,0.1c0.2-0.2,0.5-0.4,0.8-0.6l0,0c0.2-0.2,0.5-0.3,0.8-0.4C34.4,12,34.2,12,34.1,12
			c-0.2,0-0.5,0.1-0.6,0.2l-0.4-0.4l0.4,0.4C33.1,12.4,33,12.7,33,13z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M37.2,6.3c-0.1-0.1-0.3-0.2-0.4-0.2l0.1,0.4l0.7,3.9l0,0l0,0.1c0,0,0,0,0-0.1c0.3-0.2,0.7-0.4,1.1-0.4
			c0.4,0,0.8,0.1,1.1,0.4c0,0,0,0,0,0.1l0-0.1l0.3,0l-0.3,0l0.7-3.9l0.1-0.4c-0.5,0.4-1.2,0.6-2,0.6C38.2,6.6,37.7,6.5,37.2,6.3z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="31.4,6 31.2,5.7 31.4,6 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M37.6,45.4h-6.3v8h6.3V45.4z M36.2,51.9h-3.5v-5.2h3.5V51.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M32.5,43.4c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9S32.5,42.9,32.5,43.4z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M36.6,4.9L36.6,4.9c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.3,0.3,0.5,0.5c0.4,0.3,1,0.5,1.6,0.5
			c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.6-0.4,0.8-0.7c0-0.1,0.1-0.1,0.1-0.2l0,0c0,0,0-0.1,0-0.1c-0.7-0.1-1.4-0.1-2.2-0.1
			c-0.7,0-1.5,0.1-2.2,0.1C36.6,4.8,36.6,4.9,36.6,4.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M33.4,7.4c-0.3,0-0.6-0.1-0.9-0.1l0.2,0.3l2.1,3.5l0.1,0.1c0-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.5-0.4,0.8-0.5
			l0,0c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.5,0.1,0.7,0.2l0-0.1l-0.7-3.9l-0.1-0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.4-0.9,0.7-1.5,0.9
			C34.2,7.4,33.8,7.4,33.4,7.4z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="36.3,4.6 36.2,4.5 36.3,4.6 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M46.3,6.5c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.4,0.6,0.8,0.9l0,0C47.8,9,48.4,9.2,49,9.2
			c0.3,0,0.5,0,0.7-0.1C49.8,9,49.9,9,49.9,9L50,8.9c0,0,0,0,0,0l0,0c-0.6-0.5-1.1-1-1.8-1.4l0.1-0.2l-0.1,0.2
			c-0.6-0.4-1.3-0.8-2-1.1C46.3,6.4,46.3,6.4,46.3,6.5z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M41.5,5c0,0.3,0.2,0.6,0.4,0.9c0.3,0.3,0.7,0.6,1.2,0.8L43,7.2l0.2-0.5c0.3,0.1,0.7,0.2,1,0.2
			c0.4,0,0.8-0.1,1.1-0.3c0.2-0.1,0.3-0.2,0.4-0.3l0.1-0.1c0,0,0,0,0,0c-0.7-0.3-1.4-0.5-2.1-0.8C43,5.1,42.3,5,41.6,4.9
			C41.6,4.9,41.5,5,41.5,5z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M41.3,6.1l-0.1,0.5l0,0l-0.7,3.9l0,0l0,0.1c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0,0.5,0.1
			c0.3,0.1,0.6,0.3,0.8,0.5c0.1,0.1,0.1,0.2,0.2,0.2l0.1-0.1l2.1-3.5l0.2-0.3c-0.3,0.1-0.6,0.1-0.9,0.1c-0.4,0-0.8-0.1-1.2-0.2
			c-0.6-0.2-1.1-0.5-1.5-0.9C41.5,6.2,41.4,6.1,41.3,6.1z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="41.3,4.6 41.3,4.5 41.3,4.6 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M38.8,0C17.4,0,0,17.4,0,38.8c0,21.4,17.4,38.8,38.8,38.8s38.8-17.4,38.8-38.8C77.6,17.4,60.2,0,38.8,0z
			 M19.7,19.3L19.7,19.3c0-0.1,0-0.2,0-0.4c0-4.8,2.1-9.1,5.6-12.2c3.5-3.1,8.2-5,13.5-5c5.3,0,10,1.9,13.5,5
			c3.5,3.1,5.6,7.4,5.6,12.2c0,0.1,0,0.2,0,0.4h0v38h0c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3V19.9h0l0-0.3
			c0-0.2,0-0.4,0-0.7c0-4.6-2.1-8.7-5.4-11.7c-3.3-3-8-4.9-13.1-4.9c-5.1,0-9.7,1.9-13.1,4.9c-3.3,3-5.4,7.2-5.4,11.7
			c0,0.2,0,0.4,0,0.7l0,0.3h0v37.4c0,0.2-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3V19.3z M56.1,20l0-0.3c0-0.2,0-0.4,0-0.6
			c0-4.3-1.9-8.2-5.1-11c-3.1-2.8-7.5-4.6-12.3-4.6C34,3.5,29.6,5.2,26.5,8c-3.1,2.8-5.1,6.7-5.1,11c0,0.2,0,0.4,0,0.6l0,0.3h0v36.8
			c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3V19.4h0c0-0.1,0-0.2,0-0.3c0-4.5,2-8.5,5.3-11.5c3.3-2.9,7.7-4.7,12.7-4.7
			c5,0,9.4,1.8,12.7,4.7c3.3,2.9,5.3,7,5.3,11.5c0,0.1,0,0.2,0,0.3h0v37.4h0c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3L56.1,20
			L56.1,20z M53.6,54.5h0.6v0.6h0.6v1.7h-5.7v-1.7h0.6v-0.6h0.6l0.6-30.8h-0.6c0-0.6-0.5-1.1-1.1-1.1V22h5.7v0.6
			c-0.6,0-1.1,0.5-1.1,1.1H53L53.6,54.5z M54.2,21.4H23.4c0-0.9-0.8-1.7-1.7-1.7v-0.6h34.2v0.6C55,19.7,54.2,20.5,54.2,21.4z
			 M47.9,22.5v33.7H29.7V22.5H47.9z M27.4,54.5H28v0.6h0.6v1.7h-5.7v-1.7h0.6v-0.6H24l0.6-30.8H24c0-0.6-0.5-1.1-1.1-1.1V22h5.7v0.6
			c-0.6,0-1.1,0.5-1.1,1.1h-0.6L27.4,54.5z M25.1,10.7c0.5-0.7,1.1-1.4,1.8-2l0.2,0.2l0,0l-0.2-0.2C27.5,8,28.2,7.5,28.9,7
			c0.7-0.5,1.5-0.9,2.3-1.3c0.8-0.4,1.6-0.7,2.4-0.9c0.8-0.2,1.7-0.4,2.5-0.5l0,0c0.8-0.1,1.7-0.2,2.6-0.2c0.9,0,1.7,0.1,2.6,0.2
			l0,0c0.9,0.1,1.7,0.3,2.5,0.5c0.8,0.2,1.7,0.5,2.4,0.9l0,0c0.8,0.4,1.6,0.8,2.3,1.3l0,0c0.7,0.5,1.4,1.1,2.1,1.7l0,0
			c0.7,0.6,1.3,1.3,1.8,2l-0.2,0.2l0.2-0.2c0.5,0.7,1,1.5,1.4,2.3h0c0.3,0.7,0.6,1.5,0.8,2.3l0,0l0.1,0.3v0h0h0
			c0.2,0.9,0.3,1.8,0.3,2.7v0.3h-0.3h-0.3h-2h-4.6h-1.1h-4h-4h-4h-4h-1.1h-4.6h-2h-0.3h-0.3v-0.3c0-0.9,0.1-1.8,0.3-2.7l0.1-0.3l0,0
			c0.2-0.8,0.5-1.6,0.8-2.3l0,0C24.1,12.2,24.5,11.4,25.1,10.7l0.2,0.2L25.1,10.7z M18.8,60.2l2.3-2.3h8v-0.6h19.4v0.6h8l2.3,2.3
			v2.3H18.8V60.2z M61,68.2H16.5v-2.3l2.3-2.3h39.9l2.3,2.3V68.2z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M31.8,6.1l0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0.3,0,0.6-0.1,1-0.2
			c0.5-0.2,0.9-0.5,1.2-0.8C35.9,5.6,36,5.3,36,5c0,0,0-0.1,0-0.1c-0.7,0.1-1.5,0.3-2.2,0.5l0,0C33.1,5.6,32.5,5.8,31.8,6.1
			C31.8,6.1,31.8,6.1,31.8,6.1z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M17.4,65.9c0,0.2,0.1,0.3,0.3,0.3h42.2c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3H17.7
			C17.5,65.6,17.4,65.7,17.4,65.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M57.6,59.9H20c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h37.6c0.2,0,0.3-0.1,0.3-0.3
			C57.9,60,57.8,59.9,57.6,59.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M27.5,8.9C27.6,8.9,27.6,8.9,27.5,8.9L27.7,9c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.5,0.1,0.7,0.1
			c0.6,0,1.2-0.2,1.7-0.6v0c0.3-0.3,0.6-0.6,0.8-0.9c0.2-0.3,0.3-0.6,0.3-0.9c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1
			c-0.7,0.3-1.3,0.7-2,1.1C28.6,7.9,28.1,8.3,27.5,8.9L27.5,8.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M24.4,12.9C24.4,13,24.4,13,24.4,12.9l0.2,0.1c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.1,1.1-0.3c0.4-0.2,0.7-0.6,1-1
			l0,0c0.3-0.5,0.5-1.1,0.5-1.5c0-0.3-0.1-0.5-0.1-0.7c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.6,0.5-1.1,1.1-1.5,1.7l0,0
			C25.1,11.6,24.7,12.3,24.4,12.9z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M53.4,13.5c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.3-0.4,0.8-0.4,1.4
			c0,0.2,0,0.4,0.1,0.6l-0.3,0l0.3,0c0.1,0.6,0.4,1.1,0.8,1.5c0.3,0.3,0.7,0.5,1,0.5h0.2c0-0.8-0.1-1.6-0.3-2.3v0h0
			C54,14.9,53.8,14.2,53.4,13.5z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M31.5,16c-0.1,0.3-0.2,0.6-0.3,1l0,0c-0.1,0.3-0.1,0.7-0.1,1h3.4c0-0.1,0-0.3,0.1-0.4h0c0,0,0,0,0,0
			c0,0,0,0,0,0l0,0c0-0.1,0.1-0.3,0.1-0.4L31.5,16z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M30.3,17.7c0.1,0.1,0.1,0.1,0.2,0.2c0-0.3,0.1-0.7,0.1-1L31,17l-0.3-0.1c0.1-0.3,0.2-0.6,0.3-0.9
			c-0.4,0.1-0.7,0.4-0.8,0.8l-0.3-0.1l0.3,0.1c0,0.1,0,0.1,0,0.2C30.1,17.3,30.2,17.5,30.3,17.7z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M24.4,13.6C24.4,13.6,24.3,13.6,24.4,13.6c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0.7-0.6,1.5-0.7,2.2l0,0
			c-0.2,0.7-0.3,1.5-0.3,2.3h0.2c0.4-0.1,0.7-0.2,1-0.5c0.4-0.4,0.6-0.9,0.8-1.5l0,0c0-0.2,0.1-0.4,0.1-0.6c0-0.4-0.1-0.8-0.2-1.1
			C24.8,14,24.6,13.7,24.4,13.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M35,16.6c0.1-0.1,0.1-0.3,0.2-0.4l0,0c0,0,0,0,0,0l0,0l0,0c0.1-0.1,0.2-0.2,0.3-0.3l-2.5-2
			c-0.2,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.5-0.5,0.8L35,16.6z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M27.2,11.9l0.3,0.1c-0.3,0.5-0.7,0.9-1.2,1.2c-0.2,0.1-0.4,0.2-0.5,0.3l0.4,0.1l4.2,1.5v0l0.2,0.1
			c0-0.1,0-0.2,0-0.4c0-0.3,0.1-0.6,0.3-0.9c0.2-0.4,0.7-0.7,1.1-0.7l0,0l-3.4-2.7L28,10c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.8
			L27.2,11.9z"
            />
            <polygon
              className="fill-black dark:fill-white"
              points="24,13.1 23.9,13.1 24,13.1 		"
            />
            <path
              className="fill-black dark:fill-white"
              d="M31.2,14.8c0,0.2,0,0.3,0.1,0.4c0,0,0,0,0,0c0.2-0.3,0.3-0.6,0.5-0.8l0.5,0.3l-0.5-0.3
			c0.2-0.2,0.4-0.5,0.6-0.7c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.2-0.9,0.5l-0.5-0.3l0.5,0.3C31.2,14.4,31.2,14.6,31.2,14.8z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M42.4,17.7l0.2,0L42.4,17.7c0-0.2-0.1-0.4-0.2-0.6l0.3-0.1v0l-0.3,0.1c-0.1-0.2-0.2-0.4-0.3-0.5l0.2-0.2
			l-0.2,0.2c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.3-0.3-0.5-0.4l0.2-0.2L41,15.7c-0.2-0.1-0.3-0.2-0.5-0.3l0.1-0.3l0,0l-0.1,0.3
			c-0.2-0.1-0.4-0.2-0.6-0.2l0.2-0.5L40,15.2c-0.2-0.1-0.4-0.1-0.6-0.1l0,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0l0,0
			c-0.2,0-0.4,0.1-0.6,0.1L37.5,15l0.1,0.3c-0.2,0.1-0.4,0.1-0.6,0.2l-0.1-0.3l0,0l0.1,0.3c-0.2,0.1-0.4,0.2-0.5,0.3l-0.2-0.2
			l0.2,0.2c-0.2,0.1-0.3,0.2-0.5,0.4l-0.2-0.2l0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.5l-0.2-0.1l0.2,0.1c-0.1,0.2-0.2,0.3-0.3,0.5L35.1,17
			v0l0.3,0.1c-0.1,0.2-0.2,0.4-0.2,0.6l-0.3-0.1l0.3,0.1c0,0.1,0,0.2,0,0.3h3.7h3.7C42.4,17.9,42.4,17.8,42.4,17.7z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M42.1,12.3c-0.3-0.1-0.6-0.2-0.9-0.3l0.1-0.4l-0.1,0.4c-0.3-0.1-0.6-0.2-0.9-0.2l-0.5,2.9
			c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1L42.1,12.3z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M50.5,9.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.5,0.1,1,0.5,1.5
			c0.3,0.4,0.6,0.7,1,1c0.4,0.2,0.8,0.3,1.1,0.3c0.1,0,0.2,0,0.3,0l0.1,0c0,0,0.1,0,0.1,0c-0.3-0.7-0.7-1.3-1.2-2l0,0
			C51.6,10.4,51.1,9.8,50.5,9.2z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M52.8,17.8c-0.4-0.5-0.8-1.1-0.9-1.8l0,0c0-0.2-0.1-0.5-0.1-0.7c0-0.5,0.1-0.9,0.3-1.3c0-0.1,0.1-0.1,0.1-0.2
			l-0.6,0.2l-4.2,1.5l-0.1,0c0.4,0.2,0.7,0.6,0.8,1.1c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.7-0.3,1h0.2h4.6H53
			C53,17.9,52.9,17.9,52.8,17.8z"
            />
            <path
              className="fill-black dark:fill-white"
              d="M29.5,17c0-0.1,0-0.2,0-0.3h0c0.1-0.5,0.4-0.9,0.8-1.1l-0.1,0l0.1-0.4l-0.1,0.4L26,14.1l-0.6-0.2
			c0.2,0.4,0.3,0.9,0.3,1.5c0,0.2,0,0.5-0.1,0.7l-0.3,0l0.3,0c-0.1,0.7-0.5,1.3-0.9,1.8c-0.1,0.1-0.1,0.1-0.2,0.2h0.6h4.6h0.2
			C29.6,17.7,29.5,17.4,29.5,17z"
            />
          </g>
          <g>
            <path
              className="fill-black dark:fill-white"
              d="M67.8,5c0-2.9,2.2-5,4.9-5c2.7,0,4.9,2.1,4.9,5c0,2.9-2.2,5-4.9,5C70,9.9,67.8,7.8,67.8,5z M76.7,5
			c0-2.4-1.7-4.2-4-4.2c-2.3,0-4,1.8-4,4.2c0,2.5,1.7,4.2,4,4.2C75,9.2,76.7,7.4,76.7,5z M70.8,2.3h2.4c1,0,1.7,0.6,1.7,1.5
			c0,0.6-0.3,1.1-1,1.3v0c0.5,0.1,0.8,0.4,0.8,1.1c0.1,0.7,0,1.3,0.2,1.4v0.1h-1c-0.1-0.1-0.2-0.7-0.2-1.2s-0.3-0.9-1-0.9h-0.9v2.1
			h-1V2.3z M71.8,4.6H73c0.6,0,0.9-0.3,0.9-0.7S73.6,3.1,73,3.1h-1.1V4.6z"
            />
          </g>
        </g>
      </svg>
    </Fragment>
  );
}

export default LogoTHR;
