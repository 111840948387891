import React, { useContext, useState, useEffect } from "react";
import Alert from "../Alert";
import { depositCheck } from "../../utils/depositCheck";
import AppContext from "../../Context/AppContext";

function DepositChecker({
  formData,
  calculatedPropertyPurchasePrice,
  propertyRegionDetails,
}) {
  const { showResults } = useContext(AppContext);

  const [alerts, setAlerts] = useState({});

  useEffect(() => {
    try {
      const depositCheckResult = depositCheck(
        calculatedPropertyPurchasePrice,
        formData.deposit,
        formData.buyToLet,
        formData.schemes === "helpToBuy" ? true : false,
        //formData.helpToBuy,
        //formData.firstHomes,
        formData.schemes === "firstHomes" ? true : false,
        formData.firstHomesGreaterLondon,
        propertyRegionDetails
      );

      setAlerts({ ...alerts, deposit: depositCheckResult });
    } catch {
      return false;
    }
  }, [formData, calculatedPropertyPurchasePrice]);

  return (
    <>
      {showResults &&
      calculatedPropertyPurchasePrice > 0 &&
      formData.deposit &&
      formData.schemes !== "sharedOwnership" ? (
        <div className="mb-8 rounded-lg bg-white p-4 shadow-xl shadow-purple-500/25 dark:bg-black dark:shadow-purple-500/50">
          <h2 className="mb-4 text-3xl leading-none  text-black dark:text-white ">
            Deposit
          </h2>

          <div className="mb-0">
            {alerts.deposit && (
              <Alert
                type={alerts.deposit[0]}
                text={alerts.deposit[1]}
                // background={alerts.deposit[2]}
              />
            )}
          </div>

          {/* <div>
            <p className="text-xs text-black dark:text-white">
              The above results are based on the{" "}
              {formData.buyToLet ? "deposit" : "income"} figure you've provided.
              Please be aware that{" "}
              {formData.buyToLet
                ? "rental income and financial commitments will need to be"
                : "credit commitments and credit ratings have not been"}{" "}
              taken into consideration.
            </p>
          </div> */}
        </div>
      ) : null}
    </>
  );
}

export default DepositChecker;
