import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CallToActions from "../CallToActions";

import percentage from "calculate-percentages";
import Alert from "../Alert";
import ResultsBadge from "./ResultsBadge";
import { priceFormatter } from "../../utils/PriceFormatter";
import { ButtonSmall } from "../Buttons";
//import options from "../../data/options.json";
import settings from "../../data/settings.json";

import AppContext from "../../Context/AppContext";

function PropertyPurchasePrice({
  formData,
  calculatedPropertyPurchasePrice,
  setCalculatedPropertyPurchasePrice,
  propertyMarketValue,
  firstHomesQualify,
  // propertyRegionDetails,
}) {
  const { showResults } = useContext(AppContext);

  const [alerts, setAlerts] = useState({});

  //let navigate = useNavigate();

  useEffect(() => {
    setAlert();
  }, [formData, calculatedPropertyPurchasePrice]);

  const setAlert = () => {
    // const alert = formData.firstHomes

    const alert =
      formData.schemes === "firstHomes"
        ? firstHomesQualify
          ? [
              "success",
              <span>
                A standard discount of <span className="font-bold">30%</span>{" "}
                has been applied for the{" "}
                <span className="font-bold">First Homes</span> scheme.
              </span>,
              "white",
            ]
          : [
              "warning",
              <span>
                Property purchase price above maximum for{" "}
                {formData.firstHomesGreaterLondon
                  ? "Greater London"
                  : "outside Greater London"}
                . Maximum price after discount must not exceed{" "}
                <span className="font-bold">
                  {priceFormatter(
                    Math.floor(
                      formData.firstHomesGreaterLondon
                        ? settings.firstHomes.priceCapLondon
                        : settings.firstHomes.priceCap
                    )
                  )}
                </span>
                .<br />
                <span className="font-bold">First Homes</span> standard{" "}
                <span className="font-bold">30%</span> discount applied.
              </span>,
              "white",
            ]
        : null;

    setAlerts({ ...alerts, firstHomes: alert });
  };

  // const { propertyRegion, helpToBuy, firstHomes, deposit, income } = formData;

  return (
    <>
      {/* {!formData.sharedOwnership ? ( */}
      {/* // <div className="bg-gradient-to-tl from-rose-500  via-pink-500 to-fuchsia-500 shadow-md shadow-purple-500/25 dark:shadow-purple-500/50 rounded-lg p-4 mb-8 bg-op"> */}
      {/* <div className="mb-8 rounded-lg border border-gray-200 bg-white p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black dark:shadow-purple-500/50"> */}
      <h2 className="mb-4 text-3xl leading-none text-black dark:text-white">
        {/* <span className="inline-block bg-clip-text text-transparent bg-gradient-to-tl from-rose-500  via-pink-500 to-fuchsia-500"> */}
        Property Purchase Price
        {/* </span> */}
      </h2>

      <>
        <div className="mb-0 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900">
          {/* {formData.firstHomes && !formData.sharedOwnership ? ( */}
          {formData.schemes === "firstHomes" ? (
            <ResultsBadge
              size="large"
              format="price"
              colorScheme={0}
              result={showResults && Math.floor(propertyMarketValue)}
              label="Property market value"
              help={null}
              last={false}
            />
          ) : null}

          {formData.schemes === "sharedOwnership" ? (
            <>
              <ResultsBadge
                size="large"
                format="price"
                colorScheme={0}
                result={
                  showResults &&
                  Math.floor(formData.adjustedPropertyPurchasePrice)
                }
                label="Property purchase price"
                help={null}
                last={false}
              />

              <ResultsBadge
                size="small"
                format="price"
                colorScheme={null}
                result={
                  showResults
                    ? (formData.adjustedPropertyPurchasePrice *
                        formData.adjustedSharedOwnershipPercentage) /
                      100
                    : 0
                }
                label="Share Price"
                help={null}
                last={true}
              />
            </>
          ) : null}
          {formData.schemes !== "sharedOwnership" ? (
            <ResultsBadge
              size="large"
              format="price"
              colorScheme={0}
              result={showResults && calculatedPropertyPurchasePrice}
              label={`Your calculated ${
                formData.schemes === "firstHomes" ? "discounted " : ""
              } property purchase price`}
              help={null}
              last={true}
            />
          ) : null}

          {/* {calculatedPropertyPurchasePrice && (
            <div className="mt-4">
              <CallToActions />
            </div>
          )} */}
        </div>
      </>

      {showResults && alerts.firstHomes && calculatedPropertyPurchasePrice ? (
        <div className="mb-0 mt-2">
          <Alert
            type={alerts.firstHomes[0]}
            text={alerts.firstHomes[1]}
            // background={alerts.firstHomes[2]}
          />
        </div>
      ) : null}

      {/* <div>
            <p className="text-xs text-black dark:text-white">
              The above results are based on the{" "}
              {formData.buyToLet ? "deposit" : "income"} figure you've provided.
              Please be aware that{" "}
              {formData.buyToLet
                ? "rental income and financial commitments will need to be"
                : "credit commitments and credit ratings have not been"}{" "}
              taken into consideration.
            </p>
          </div> */}
      {/* </div> */}
      {/* ) : null} */}
    </>
  );
}

export default PropertyPurchasePrice;
