import React from "react";
import { useNavigate } from "react-router-dom";

import Title from "../components/Title";
import { ButtonDefault } from "../components/Buttons";

const HomePage = () => {
  let navigate = useNavigate();

  return (
    <>
      <div className="mx-auto max-w-2xl px-2 md:px-4">
        <div className="flex min-h-screen flex-col items-center justify-center pt-12">
          <div className="frost rounded-lg border border-gray-200 bg-white/50 p-8 pb-0 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50">
            <div className="mb-0">
              <Title title="Find my budget" stage="1" align="start" />
            </div>

            <div className="mb-8 pl-16 xs:pl-20">
              <p className="mb-2 text-lg text-black dark:text-white xs:text-xl">
                With our easy to use mortgage payment calculator, you can:
              </p>
              <p className="mb-2 text-lg text-black dark:text-white xs:text-xl">
                Obtain an estimate of how much you can afford to borrow to buy a
                home.
              </p>
              <p className="mb-2 text-lg text-black dark:text-white xs:text-xl">
                Calculate your potential monthly mortgage repayments.
              </p>
              <p className="mb-2 text-lg text-black dark:text-white xs:text-xl">
                Learn about the other costs associated with your purchase,
                including stamp duty.
              </p>
            </div>

            <div className="mb-0">
              <Title title="Find my mortgage" stage="2" align="start" />
              <p className="mb-8 pl-16 text-lg text-black dark:text-white xs:pl-20 xs:text-xl">
                Compare mortgage deals based on your affordability to find the
                right deal for you.
              </p>
            </div>

            <div className="mb-8">
              <Title title="Get in touch" stage="3" align="start" />

              <p className="mb-8 pl-16 text-lg text-black dark:text-white xs:pl-20 xs:text-xl">
                Arrange a date and time for a qualified adviser to call you.
              </p>
            </div>

            <div className="mb-8">
              <button
                className="inline-flex w-full max-w-sm items-center justify-center whitespace-nowrap rounded-full bg-emerald-500 p-4 text-xl font-semibold uppercase leading-none text-white  transition-colors duration-500  hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 dark:hover:bg-emerald-400 disabled:dark:bg-gray-600 disabled:dark:text-gray-800"
                onClick={() => navigate("/find-budget")}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
