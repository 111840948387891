import { useState } from "react";
import { sample } from "lodash";
import reviews from "../data/reviews.json";
import TrustpilotLogo from "./svg/TrustpilotLogo";

const ReviewsBox = () => {
  const [review] = useState(sample(reviews));

  return (
    <div className="rounded-lg border border-gray-200 bg-white/50 p-4 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50 md:p-8">
      <div className="flex flex-col sm:flex-row">
        <div className="mb-4 sm:mr-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-auto w-12 text-gray-300 dark:text-gray-700"
            viewBox="0 0 448 512"
            fill="currentColor"
          >
            <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64h-64c-35.3 0-64-28.7-64-64V216z" />
          </svg>
        </div>
        <div>
          <p className="mb-2 text-sm font-bold text-black dark:text-white">
            {review.reviewTitle}
          </p>
          <p className="mb-4 text-sm text-black dark:text-white">
            {review.review}
          </p>

          <a
            className="mb-0"
            href="https://www.trustpilot.com/review/thresholdmortgages.com"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <TrustpilotLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReviewsBox;
