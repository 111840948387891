import React, { useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonDefault } from "../Buttons";
import AppContext from "../../Context/AppContext";

function GetInTouch(props) {
  const { calculatedPropertyPurchasePrice, showResults, referral } =
    useContext(AppContext);

  let navigate = useNavigate();

  return (
    <Fragment>
      {calculatedPropertyPurchasePrice || showResults ? (
        <div className="frost mb-8 rounded-lg bg-white/50 p-8 shadow-xl shadow-purple-500/25 dark:bg-black/50 dark:shadow-purple-500/50">
          {/* <h2 className="mb-4 text-center text-3xl leading-none text-black dark:text-white">
            Get in touch
          </h2> */}

          <div className="mb-2 flex items-center justify-center">
            <div className="-mr-4 flex h-20 w-20 items-center justify-center rounded-full bg-gray-100 text-5xl font-light text-gray-500 dark:bg-gray-900">
              3
            </div>

            <h2 className="text-3xl leading-none text-black dark:text-white">
              Get in touch
            </h2>
          </div>

          <p className="mb-2 text-center text-black dark:text-white">
            For an initial fee free mortgage review and affordability check, get
            in touch with one of our qualified mortgage advisers.
          </p>
          <div className="mb-4 flex justify-center">
            <ButtonDefault
              color="emerald"
              label="Get in touch"
              onClick={() => navigate("/contact")}
            />
          </div>

          <p className="mb-0 text-center text-black dark:text-white">
            Or call us 7 days a week on{" "}
            <a
              href={`tel:${referral ? "03458945522" : "03300249115"}`}
              className="text-lg font-bold text-emerald-500 hover:underline"
            >
              {referral ? (
                <span>03458&nbsp;945&nbsp;522</span>
              ) : (
                <span>03300&nbsp;249&nbsp;115</span>
              )}
            </a>
          </p>
        </div>
      ) : null}
    </Fragment>
  );
}

export default GetInTouch;
