import { Link, useNavigate, useLocation } from "react-router-dom";
import { ButtonLarge } from "./Buttons";

const Navigation = (props) => {
  let navigate = useNavigate();
  let location = useLocation();

  const { pathname } = location;

  return (
    <>
      {pathname !== "/" ? (
        <div className="w-full">
          <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
            <nav className="mt-20 flex w-full flex-wrap justify-start gap-4">
              <div className="flex items-center">
                <span className="mr-2 text-3xl font-light text-gray-400">
                  <span
                    className={`${
                      location.pathname === "/find-budget"
                        ? "text-indigo-500"
                        : "text-gray-400"
                    }`}
                  >
                    1
                  </span>
                  :
                </span>
                <ButtonLarge
                  color={
                    location.pathname === "/find-budget"
                      ? "plainActive"
                      : "plain"
                  }
                  label="Find my budget"
                  onClick={() => navigate("/find-budget")}
                />
              </div>

              <div className="flex items-center">
                <span
                  className="mr-2 text-3xl font-light text-gray-400
              "
                >
                  <span
                    className={`${
                      location.pathname === "/find-mortgage"
                        ? "text-indigo-500"
                        : "text-gray-400"
                    }`}
                  >
                    2
                  </span>
                  :
                </span>
                <ButtonLarge
                  color={
                    location.pathname === "/find-mortgage"
                      ? "plainActive"
                      : "plain"
                  }
                  label="Find my mortgage"
                  onClick={() => navigate("/find-mortgage")}
                />
              </div>

              <div className="flex items-center">
                <span
                  className="mr-2 text-3xl font-light text-gray-400
            "
                >
                  <span
                    className={`${
                      location.pathname === "/contact"
                        ? "text-indigo-500"
                        : "text-gray-400"
                    }`}
                  >
                    3
                  </span>
                  :
                </span>
                <ButtonLarge
                  color={
                    location.pathname === "/contact" ? "plainActive" : "plain"
                  }
                  label="Get in touch"
                  onClick={() => navigate("/contact")}
                />
              </div>
            </nav>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navigation;
