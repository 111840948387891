import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  assign,
  find,
  has,
  isEmpty,
  filter,
  merge,
  take,
  uniq,
  orderBy,
  pick,
  reject,
  sample,
  size,
  forEach,
} from "lodash";
// import _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import CountUp from "react-countup";
import percentage from "calculate-percentages";

import Title from "../components/Title";
import InputField from "../components/form/InputField";
import InputPriceField from "../components/form/InputPriceField";
import RadioGroup from "../components/form/RadioGroup";
import { ButtonSmall, ButtonDefault } from "../components/Buttons";
import ResultsBadge from "../components/widgets/ResultsBadge";
import FilterButtons from "../components/FilterButtons";
import ProductCard from "../components/ProductCard";
import Alert from "../components/Alert";
import Tooltip from "../components/Tooltip";
//import testProductData from "../data/testProductsData.json";

// import { priceFormatter } from "../utils/PriceFormatter";
// import { percentageFormatter } from "../utils/percentageFormatter";
import AppContext from "../Context/AppContext";

import { calculatePayableOverTermIncludeFees } from "../utils/productCalculations";
import {
  getMinDepositAmount,
  getSharedOwnershipDepositAlert,
} from "../utils/getSharedOwnershipDepositAlert";
import removeScrollLockClasses from "../utils/removeScrollLockClasses";

import TrustpilotLogo from "../components/svg/TrustpilotLogo";
import { ReactComponent as ArrowSmallUp } from "../img/arrow-small-up.svg";
import ReviewsBox from "../components/ReviewsBox";

import { priceFormatter } from "../utils/PriceFormatter";
import { percentageFormatter } from "../utils/percentageFormatter";

const FindMortgagePage = () => {
  let navigate = useNavigate();

  const {
    formData,
    setFormData,
    calculatedPropertyPurchasePrice,
    calculatedLaonAmount,
    search,
    setSearch,
    rates,
    setRates,
    lowestRates,
    setLowestRates,
  } = useContext(AppContext);

  const defaultSearchParameters = {
    purchaseType: "HOME_MOVER",
    buyToLet: 0,
    sharedOwnership: 0,
    propertyPurchasePrice: 200000,
    deposit: 100000,
    mortgageLoanAmount: 100000,
    mortgageTerm: 30,
    income: 50000,
  };

  const defaultFilters = {
    rateType: { _id: 0, value: null },
    ratePeriod: { _id: 0, value: null },
    rateSteps: { _id: 0, value: null },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  // const [lendersLogos, setLendersLogos] = useState([]);
  const [searchFormData, setSearchFormData] = useState([]);
  const [searchFormChange, setSearchFormChange] = useState({});
  const [submittedSearchFormData, setSubmittedSearchFormData] = useState(
    defaultSearchParameters
  );

  const [filters, setFilters] = useState(defaultFilters);

  //const [productsPerPage, setProductsPerPage] = useState(50);
  const [productsPerPage, setProductsPerPage] = useState(10);

  // const maxProductsPerPage = 3000;
  // const productsPerLoad = 50;
  const maxProductsPerPage = 50;
  const productsPerLoad = 3;
  const [loadMoreButton, setLoadMoreButton] = useState(true);
  const [sortProductsBy, setSortProductsBy] = useState({
    selected: "initialRate",
  });
  const [totalProductCount, setTotalProductCount] = useState(null);
  const [filteredProductData, setFilteredProductData] = useState([]);
  const [showResultsHeader, setShowResultsHeader] = useState(false);
  const [alerts, setAlerts] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    loadSchemes();

    removeScrollLockClasses();

    return () => {
      setProductData([]);
      // setLendersLogos([]);
    };
  }, []);

  useEffect(() => {
    const { totalProductCount, data } = filterAndSortProducts();

    setTotalProductCount(totalProductCount);
    setFilteredProductData(data);

    if (totalProductCount <= productsPerPage) setLoadMoreButton(false);
    else {
      setLoadMoreButton(true);
    }
  }, [filters, sortProductsBy]);

  useEffect(() => {
    document.getElementById("search-results").scrollIntoView();
  }, [isLoading]);

  useEffect(() => {
    let { name, value } = searchFormChange;

    const propertyPurchasePrice = !searchFormData.sharedOwnership
      ? searchFormData.propertyPurchasePrice
      : (searchFormData.propertyPurchasePrice *
          searchFormData.sharedOwnershipPercentage) /
        100;

    // if (name === "buyToLet") {
    //   const incomeValue =
    //     value === 0
    //       ? formData.income
    //         ? formData.income
    //         : searchFormData.income
    //         ? searchFormData.income
    //         : defaultSearchParameters.income
    //       : defaultSearchParameters.income;

    //   setSearchFormData({
    //     ...searchFormData,
    //     income: incomeValue,
    //   });
    // }

    if (name === "sharedOwnership") {
      const mortgageLoanAmountValue =
        value === 1
          ? propertyPurchasePrice - searchFormData.deposit
          : searchFormData.propertyPurchasePrice - searchFormData.deposit;

      setSearchFormData({
        ...searchFormData,
        mortgageLoanAmount: mortgageLoanAmountValue,
      });

      // if (value === 1)
      //   setSearchFormData({
      //     ...searchFormData,
      //     mortgageLoanAmount: propertyPurchasePrice - searchFormData.deposit,
      //   });
      // else {
      //   setSearchFormData({
      //     ...searchFormData,
      //     mortgageLoanAmount:
      //       searchFormData.propertyPurchasePrice - searchFormData.deposit,
      //   });
      // }
    }

    if (name === "sharedOwnershipPercentage") {
      setSearchFormData({
        ...searchFormData,
        mortgageLoanAmount: propertyPurchasePrice - searchFormData.deposit,
      });
    }

    if (name === "deposit") {
      setSearchFormData({
        ...searchFormData,
        mortgageLoanAmount: propertyPurchasePrice - value,
      });
    }

    if (name === "propertyPurchasePrice") {
      value = !searchFormData.sharedOwnership
        ? value
        : (value * searchFormData.sharedOwnershipPercentage) / 100;
      setSearchFormData({
        ...searchFormData,
        mortgageLoanAmount: value - searchFormData.deposit,
      });
    }

    if (name === "mortgageLoanAmount") {
      setSearchFormData({
        ...searchFormData,
        deposit: searchFormData.propertyPurchasePrice - value,
      });
    }
  }, [searchFormChange]);

  useEffect(() => {
    const loanToValue =
      (searchFormData.mortgageLoanAmount /
        searchFormData.propertyPurchasePrice) *
      100;

    const requiredDepositAmount = percentage.of(
      20,
      searchFormData.propertyPurchasePrice
    );

    const sharePrice =
      (searchFormData.propertyPurchasePrice *
        searchFormData.sharedOwnershipPercentage) /
      100;

    const depositAlert =
      searchFormData.buyToLet && loanToValue > 80 ? (
        <span>
          A minimum of <span className="font-bold">20%</span> deposit is
          required for a buy to let mortgage.{" "}
          <span className="font-bold">
            {priceFormatter(requiredDepositAmount)}
          </span>
        </span>
      ) : searchFormData.sharedOwnership &&
        searchFormData.deposit < getMinDepositAmount(sharePrice) ? (
        getSharedOwnershipDepositAlert(null, sharePrice)[1]
      ) : null;

    setAlerts({
      ...alerts,
      deposit: depositAlert,
    });

    //getMinDepositAmount(sharePrice), getSharedOwnershipDepositAlert(alertType, sharePrice)
  }, [searchFormData]);

  const filterAndSortProducts = () => {
    let filteredData = productData;

    filteredData = filters.rateType.value
      ? filteredData.filter(
          (product) => product.initialRateType[filters.rateType.value] === true
        )
      : filteredData;

    filteredData =
      filters.ratePeriod._id === 1
        ? filteredData.filter((product) => product.rateControlPeriod / 12 < 3)
        : filters.ratePeriod._id === 2
        ? filteredData.filter(
            (product) =>
              product.rateControlPeriod / 12 > 3 &&
              product.rateControlPeriod / 12 < 5
          )
        : filters.ratePeriod._id === 3
        ? filteredData.filter(
            (product) =>
              product.rateControlPeriod / 12 >= 5 &&
              product.rateControlPeriod / 12 < 6
          )
        : filters.ratePeriod._id === 4
        ? filteredData.filter((product) => product.rateControlPeriod / 12 >= 6)
        : filteredData;

    filteredData =
      filters.rateSteps.value !== null
        ? filteredData.filter(
            (product) => product.graphRateStepsCount === filters.rateSteps.value
          )
        : filteredData;

    const sortOption = sortProductOptions.find(
      (option) => option.value === sortProductsBy.selected
    );

    filteredData = orderBy(filteredData, sortOption.value, sortOption.sort);

    if (sortOption.value === "totalInitialCost")
      filteredData = filteredData.filter(
        (product) => product.graphRateStepsCount > 1
      );

    return { totalProductCount: filteredData.length, data: filteredData };
  };

  const loadSchemes = () => {
    setProductData([]);
    setTotalProductCount(null);
    // setLendersLogos([]);
    setIsLoading(true);
    setProductsPerPage(10);
    setLoadMoreButton(true);

    const fetchData = async () => {
      try {
        // Populate endpoint with form data
        const purchaseTypeParameter =
          (has(searchFormData, "firstTimeBuyer") &&
            searchFormData.firstTimeBuyer === 1) ||
          (has(formData, "firstTimeBuyer") && formData.firstTimeBuyer === 1)
            ? "FIRST_TIME_BUYER"
            : defaultSearchParameters.purchaseType;

        const buyToLetParameter =
          has(searchFormData, "buyToLet") && searchFormData.buyToLet !== null
            ? searchFormData.buyToLet
            : has(formData, "buyToLet") && formData.buyToLet !== null
            ? formData.buyToLet
            : defaultSearchParameters.buyToLet;

        const sharedOwnershipParameter =
          has(searchFormData, "sharedOwnership") &&
          searchFormData.sharedOwnership !== null
            ? searchFormData.sharedOwnership
            : // : has(formData, "sharedOwnership") &&
            //   formData.sharedOwnership !== null
            formData.schemes === "sharedOwnership"
            ? //? formData.sharedOwnership
              1
            : defaultSearchParameters.sharedOwnership;

        const sharedOwnershipPercentage =
          searchFormData.sharedOwnershipPercentage
            ? searchFormData.sharedOwnershipPercentage
            : formData.sharedOwnershipPercentage
            ? formData.sharedOwnershipPercentage
            : 40;

        const propertyPurchasePriceParameter =
          searchFormData.propertyPurchasePrice
            ? searchFormData.propertyPurchasePrice
            : calculatedPropertyPurchasePrice || formData.propertyPurchasePrice
            ? //  ? formData.sharedOwnership === 1
              formData.schemes === "sharedOwnership"
              ? formData.propertyPurchasePrice
              : calculatedPropertyPurchasePrice
            : defaultSearchParameters.propertyPurchasePrice;

        const depositParameter = searchFormData.deposit
          ? searchFormData.deposit
          : formData.deposit
          ? formData.deposit
          : defaultSearchParameters.deposit;

        const mortgageLoanAmountParameter = searchFormData.mortgageLoanAmount
          ? searchFormData.mortgageLoanAmount
          : calculatedLaonAmount
          ? calculatedLaonAmount
          : defaultSearchParameters.mortgageLoanAmount;

        // const mortgageLoanAmountParameter = !formData.sharedOwnership
        //   ? searchFormData.mortgageLoanAmount
        //     ? searchFormData.mortgageLoanAmount
        //     : defaultSearchParameters.mortgageLoanAmount
        //   : calculatedLaonAmount;

        const mortgageTermParameter = searchFormData.mortgageTerm
          ? searchFormData.mortgageTerm
          : formData.term
          ? formData.term
          : defaultSearchParameters.mortgageTerm;

        const income = searchFormData.income
          ? searchFormData.income
          : formData.income
          ? formData.income
          : defaultSearchParameters.income;

        const incomeParameter =
          searchFormData.buyToLet || formData.buyToLet ? 1000000 : income;

        setSearchFormData({
          firstTimeBuyer: purchaseTypeParameter === "FIRST_TIME_BUYER" ? 1 : 0,
          buyToLet: buyToLetParameter,
          sharedOwnership: sharedOwnershipParameter,
          sharedOwnershipPercentage,
          propertyPurchasePrice: propertyPurchasePriceParameter,
          deposit: depositParameter,
          mortgageLoanAmount: mortgageLoanAmountParameter,
          mortgageTerm: mortgageTermParameter,
          income: income,
        });

        const checkSearchParameter = (parameter) => {
          const result =
            parameter === null || parameter === 0 || parameter === 1
              ? parameter === null || parameter === 0
                ? false
                : true
              : parameter;

          return result;
        };

        const { data } = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/best-buy-tables/${purchaseTypeParameter}/${checkSearchParameter(
            buyToLetParameter
          )}/${checkSearchParameter(sharedOwnershipParameter)}/${Math.round(
            propertyPurchasePriceParameter
          )}/${Math.round(depositParameter)}/${Math.round(
            mortgageLoanAmountParameter
          )}/${Math.round(mortgageTermParameter)}/${Math.round(
            incomeParameter
          )}`
        );

        // const data = testProductData;

        if (data) {
          // remove if clause
          let convertedData = [];

          data.forEach((product) => {
            let initialRateType = {};

            Object.entries(product["a:InitialRateType"]).forEach((rateType) => {
              initialRateType[rateType[0].replace("a:", "")] =
                rateType[1]._text === "true" ? true : false;
            });

            let graphRateSteps = [];

            if (product["a:GraphRateSteps"]["b:MBGraphRateStep"].length) {
              Object.entries(
                product["a:GraphRateSteps"]["b:MBGraphRateStep"]
              ).forEach((rateStep, index) => {
                graphRateSteps = {
                  ...graphRateSteps,
                  [index]: {
                    monthCount: parseFloat(rateStep[1]["b:MonthCount"]._text),
                    rate: parseFloat(rateStep[1]["b:Rate"]._text),
                    rateType: rateStep[1]["b:RateType"]._text,
                  },
                };
              });
            } else {
              const rateStep = product["a:GraphRateSteps"]["b:MBGraphRateStep"];

              graphRateSteps = {
                ...graphRateSteps,
                0: {
                  monthCount: parseFloat(rateStep["b:MonthCount"]._text),
                  rate: parseFloat(rateStep["b:Rate"]._text),
                  rateType: rateStep["b:RateType"]._text,
                },
              };
            }

            const toBoolean = (string) => {
              return string === "true" ? true : false;
            };

            convertedData.push({
              APRC: parseFloat(product["a:APRC"]._text),
              arrangementFee: parseFloat(product["a:ArrangementFee"]._text),
              bookingFee: parseFloat(product["a:BookingFee"]._text),
              cashback: parseFloat(product["a:Cashback"]._text),
              deposit: parseFloat(product["a:Deposit"]._text),
              difference:
                calculatePayableOverTermIncludeFees({
                  initialMonthlyPayment: parseFloat(
                    product["a:InitialMonthlyPayment"]._text
                  ),
                  term: parseFloat(product["a:Term"]._text),
                  rateControlPeriod: parseFloat(
                    product["a:RateControlPeriod"]._text
                  ),
                  finalMonthlyPayment: parseFloat(
                    product["a:FinalMonthlyPayment"]._text
                  ),
                  totalFees: parseFloat(product["a:TotalFees"]._text),
                  finalFee: parseFloat(product["a:FinalFee"]._text),
                  cashback: parseFloat(product["a:Cashback"]._text),
                }) - parseFloat(product["a:TotalAmountPayableOverTerm"]._text),
              ERCDescription: product["a:ERCDescription"]._text,
              finalMonthlyPayment: parseFloat(
                product["a:FinalMonthlyPayment"]._text
              ),
              finalFee: parseFloat(product["a:FinalFee"]._text),
              finalRate: parseFloat(product["a:FinalRate"]._text),
              graphRateSteps: graphRateSteps,
              graphRateStepsCount: size(graphRateSteps),
              income: incomeParameter,
              infoButton: false,
              initialMonthlyPayment: parseFloat(
                product["a:InitialMonthlyPayment"]._text
              ),
              initialRate: parseFloat(product["a:InitialRate"]._text),
              initialRateType: initialRateType,
              lenderCommonName: product["a:LenderCommonName"]._text,
              lenderID: product["a:LenderID"]._text,
              loanAmount: parseFloat(product["a:LoanAmount"]._text),
              maxLTV: Math.trunc(parseFloat(product["a:MaxLTV"]._text)),
              note: product["a:Note"]._text,
              //notes: product["a:Notes"]._text,
              otherFee: parseFloat(product["a:OtherFee"]._text),
              overpayments: toBoolean(product["a:Overpayments"]._text),
              paymentHolidays: toBoolean(product["a:PaymentHolidays"]._text),
              purchasePrice: parseFloat(product["a:PurchasePrice"]._text),
              rateControlPeriod: parseFloat(
                product["a:RateControlPeriod"]._text
              ),
              rateDescription: product["a:RateDescription"]._text,
              schemeID: product["a:SchemeID"]._text,
              telegraphicTransferFee: parseFloat(
                product["a:TelegraphicTransferFee"]._text
              ),
              term: parseFloat(product["a:Term"]._text),
              totalAmountPayableOverTerm: parseFloat(
                product["a:TotalAmountPayableOverTerm"]._text
              ),
              totalFees: parseFloat(product["a:TotalFees"]._text),

              totalInitialCost:
                parseFloat(product["a:InitialMonthlyPayment"]._text) *
                  parseFloat(product["a:RateControlPeriod"]._text) +
                parseFloat(product["a:TotalInitialFees"]._text),

              totalInitialFees: parseFloat(product["a:TotalInitialFees"]._text),
              valuationAdminFee: parseFloat(
                product["a:ValuationAdminFee"]._text
              ),
              valuationFee: parseFloat(product["a:ValuationFee"]._text),
            });
          });

          let filteredProducts = convertedData;
          // filter out rate steps
          filteredProducts = filteredProducts.filter(
            (product) =>
              product.graphRateStepsCount >= 1 &&
              product.graphRateStepsCount < 4
          );
          // filter out non discounted mortgages
          filteredProducts = reject(
            filteredProducts,
            (product) =>
              product.graphRateStepsCount === 3 &&
              !product.rateDescription.match(/discount/i)
          );

          // filter out mortgages that have a difference more than zero
          filteredProducts = filteredProducts.filter(
            (product) => Math.round(product.difference) >= 0
          );

          // filter invalid LTV mortgages
          const sharePrice =
            (propertyPurchasePriceParameter * sharedOwnershipPercentage) / 100;

          const propertyPurchasePrice = !sharedOwnershipParameter
            ? propertyPurchasePriceParameter
            : sharePrice;

          // console.log("sharePrice", sharePrice);
          // console.log("propertyPurchasePrice", propertyPurchasePrice);

          const loanToValue =
            (mortgageLoanAmountParameter / propertyPurchasePrice) * 100;

          //   const sharePrice =
          //   (searchFormData.propertyPurchasePrice *
          //     searchFormData.sharedOwnershipPercentage) /
          //   100;

          // const loanToValue = searchFormData.sharedOwnership
          //   ? (searchFormData.mortgageLoanAmount / sharePrice) * 100
          //   : (searchFormData.mortgageLoanAmount /
          //       searchFormData.propertyPurchasePrice) *
          //     100;

          // const loanToValue = searchFormData.sharedOwnership
          // ? (searchFormData.mortgageLoanAmount / sharePrice) * 100
          // : (searchFormData.mortgageLoanAmount /
          //     searchFormData.propertyPurchasePrice) *
          //   100;

          filteredProducts = filteredProducts.filter(
            (product) => product.maxLTV >= loanToValue
          );

          const products = orderBy(filteredProducts, "initialRate");

          // console.log("loanToValue", loanToValue);
          // console.log("data", data);
          // console.log("products", products);
          /////
          // console.log(
          //   "products lowest rate",
          //   products ? products[0].initialRate : "no products"
          // );
          // console.log("products", !isEmpty(products));

          if (!isEmpty(products)) {
            // console.log("update rates");

            const lowestRateProducts = products.map((product) => {
              // return pick(product, ["lender", "maxLtv", "rate", "schemeId"]);
              return {
                lender: product.lenderCommonName,
                maxLtv: product.maxLTV,
                rate: product.initialRate,
                schemeId: product.schemeID,
              };
            });

            const sortedLowestRateProducts = orderBy(
              lowestRateProducts,
              ["maxLtv", "rate"],
              ["asc", "asc"]
            );

            let updatedRates = [];

            const maxLtvPct = [60, 65, 70, 75, 80, 85, 90, 95];

            maxLtvPct.forEach((pct) => {
              const result = find(sortedLowestRateProducts, (product) => {
                return product.maxLtv === pct;
              });

              if (result) updatedRates.push(result);
            });

            const rateGroup = buyToLetParameter
              ? "buyToLet"
              : sharedOwnershipParameter
              ? "sharedOwnership"
              : "residential";

            // console.log("rateGroup", rateGroup);

            // const newRates = Object.assign(
            //   [...rates].find((item) => {
            //     return item.hasOwnProperty(rateType);
            //   }),
            //   { [rateType]: updatedRates }
            // );

            // console.log("current rates", rates);

            // const clonedRates = [...rates];

            // console.log("clonedRates", clonedRates);

            // const foundRateGroup = clonedRates.find((item) => {
            //   return item.hasOwnProperty(rateGroup);
            // });
            // console.log("foundRateGroup", foundRateGroup);

            // const filteredRateGroup = [...rates].filter(
            //   (item) => item[rateGroup]
            // );

            // console.log("filteredRateGroup", filteredRateGroup);

            // const obj = {
            //   ...filteredRateGroup,
            //   [rateGroup]: updatedRates,
            // };

            // console.log("obj", obj);

            setRates(
              rates.map((item) =>
                item.hasOwnProperty(rateGroup)
                  ? { ...item, [rateGroup]: updatedRates }
                  : item
              )
            );

            const sortedRates = orderBy(updatedRates, "rate");
            // console.log("sortedRates", sortedRates);

            const lowestRate = sortedRates.find((item) => {
              return item.rate > 0;
            });
            // console.log("lowestRate", lowestRate);

            setLowestRates({ ...lowestRates, [rateGroup]: lowestRate.rate });
          }
          /////
          setProductData(products);
          setSubmittedSearchFormData(searchFormData);
          // setLendersLogos(logos);
          setSortProductsBy({ selected: "initialRate" });
          if (products.length <= productsPerPage) setLoadMoreButton(false);
          else {
            setLoadMoreButton(true);
          }
          setFilters(defaultFilters);
          setShowResultsHeader(true);
          setTotalProductCount(products.length);
          setFilteredProductData(products);
          setIsLoading(false);
          setSearch(true);
        } else {
          setShowResultsHeader(false);
          setLoadMoreButton(false);
          setIsLoading(false);
        }
      } catch (ex) {
        console.error("ERROR", ex);
      }
    };

    fetchData();
  };

  const handleProductInfoButton = (id) => {
    const products = [...productData];

    const product = products.filter((item) => item.schemeID === id);

    product[0].infoButton = product[0].infoButton ? false : true;

    setProductData(products, product[0]);
  };

  const handleRangeChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    // if (input.maxLength)
    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    // let data = { ...calculatorData };

    input.value = isNaN(parseFloat(input.value)) ? 0 : parseFloat(input.value);

    // setCalculatorData(data);
    // setCalculatorResults(
    //   calculateMortgage(
    //     data.calcMortgageLoanAmount,
    //     data.calcMortgageTerm,
    //     data.calcInterestRate
    //   )
    // );
    doAfterChange(input);
  };
  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    // setFormData({ [input.name]: input.value });
    // onFormDataChange(input);
    doAfterChange(input);
  };

  const handlePriceChange = ({ currentTarget }) => {
    const input = {
      value: Number(currentTarget.value.replace(/[^0-9.-]+/g, "")),
      name: currentTarget.name,
    };
    // setFormData({ [input.name]: input.value });
    // onFormDataChange(input);
    doAfterChange(input);
  };

  const handleNumberChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value.replace(/[^0-9.-]+/g, ""),
      min: currentTarget.min,
      max: currentTarget.max,
      maxLength: currentTarget.maxLength,
    };

    if (input.min && input.max) {
      input.value =
        Number(input.value) > Number(input.max)
          ? input.max
          : input.value !== "" && Number(input.value) < Number(input.min)
          ? input.min
          : input.value;
    }

    // if (input.maxLength)
    if (input.value.length > input.maxLength)
      input.value = input.value.substr(0, input.maxLength);

    doAfterChange(input);
  };
  const doAfterChange = (input) => {
    setSearchFormData({ ...searchFormData, [input.name]: input.value });
    setSearchFormChange(input);
  };

  const incrementButtons = (name, increment, maxLength, min, max) => {
    const handleMinus = (name, increment, maxLength) => {
      const data = { ...searchFormData };

      const incrementValue = data[name] - increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setSearchFormData(data);
      doAfterChange({ name, value: data[name] });
    };
    const handlePlus = (name, increment) => {
      const data = { ...searchFormData };

      const incrementValue = data[name] + increment;
      const convertedValue = incrementValue.toString().substr(0, maxLength);
      data[name] = Number(convertedValue);

      setSearchFormData(data);
      doAfterChange({ name, value: data[name] });
    };

    return (
      <div className="mt-1 flex w-full justify-between">
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black  hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700  dark:focus:ring-gray-600 dark:focus:ring-offset-black	dark:disabled:bg-gray-600/50 dark:disabled:text-white/25"
          disabled={searchFormData[name] <= min}
          onClick={() => handleMinus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex items-center rounded-full bg-gray-100 px-4  py-2 text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white disabled:bg-gray-100/50 disabled:text-black/25 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600  dark:focus:ring-offset-black dark:disabled:bg-gray-600/50	dark:disabled:text-white/25"
          disabled={searchFormData[name] >= max}
          onClick={() => handlePlus(name, increment, maxLength)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    );
  };

  const handleSortProducts = ({ currentTarget }) => {
    setSortProductsBy({ selected: currentTarget.value });
  };

  const handleFilterProducts = (name, item) => {
    setFilters({ ...filters, [name]: { _id: item._id, value: item.value } });
  };

  const handleLoadMoreProducts = () => {
    const limit =
      totalProductCount <= maxProductsPerPage
        ? totalProductCount
        : maxProductsPerPage;

    const result =
      productsPerPage + productsPerLoad >= limit
        ? limit
        : productsPerPage + productsPerLoad;

    setProductsPerPage(result);

    if (result === limit) setLoadMoreButton(false);
  };

  const sortProductOptions = [
    {
      value: "initialRate",
      option: "Initial rate (Low - High)",
      order: 10,
      display: true,
      sort: "asc",
    },

    {
      value: "maxLTV",
      option: "Max LTV (High - Low)",
      order: 20,
      display: true,
      sort: "desc",
    },

    {
      value: "initialMonthlyPayment",
      option: "Monthly payments (Low - High)",
      order: 30,
      display: true,
      sort: "asc",
    },

    {
      value: "totalInitialFees",
      option: "Fees (Low - High)",
      order: 40,
      display: true,
      sort: "asc",
    },
    {
      value: "totalInitialCost",
      option: "Total cost for initial period (Low - High)",
      order: 50,
      display: true,
      sort: "asc",
    },

    {
      value: "difference",
      option: "Difference (High - Low )",
      order: 60,
      display: false,
      sort: "desc",
    },
  ];

  const sharePrice =
    (searchFormData.propertyPurchasePrice *
      searchFormData.sharedOwnershipPercentage) /
    100;

  const loanToValue = searchFormData.sharedOwnership
    ? (searchFormData.mortgageLoanAmount / sharePrice) * 100
    : (searchFormData.mortgageLoanAmount /
        searchFormData.propertyPurchasePrice) *
      100;

  return (
    <>
      <div className="mx-auto mb-16 mt-12 max-w-4xl px-2 md:px-4">
        <div className="mb-8">
          <Title title="Find my mortgage" stage="2" />
        </div>

        <div className="frost mb-16 rounded-lg border border-gray-200 bg-white/50 p-2 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50 md:p-4">
          {!isLoading ? (
            <div className="mb-12 grid w-full grid-cols-1 gap-x-8 rounded-lg border border-gray-400 bg-gradient-to-tl from-gray-100 p-2 pb-0 dark:border-gray-600 dark:bg-gray-900 dark:from-gray-900 xs:grid-cols-2 md:grid-cols-3 md:px-4">
              <div className="mb-4">
                <RadioGroup
                  name="firstTimeBuyer"
                  label="First time buyer"
                  labelCheckMark={false}
                  options={[
                    { _id: 0, value: 1, option: "Yes" },
                    { _id: 1, value: 0, option: "No" },
                  ]}
                  toolTip={null}
                  value={searchFormData.firstTimeBuyer}
                  onChange={handleRadioChange}
                  // error={errors["firstTimeBuyer"]}
                  disabled={isLoading}
                />
              </div>

              {searchFormData.sharedOwnership === 0 ? (
                <div className="mb-4">
                  <RadioGroup
                    name="buyToLet"
                    label="Buy to let"
                    labelCheckMark={false}
                    options={[
                      { _id: 0, value: 1, option: "Yes" },
                      { _id: 1, value: 0, option: "No" },
                    ]}
                    toolTip={null}
                    value={searchFormData.buyToLet}
                    onChange={handleRadioChange}
                    // error={errors["buyToLet"]}
                    disabled={isLoading}
                  />
                </div>
              ) : null}

              {searchFormData.buyToLet === 0 ? (
                <div className="mb-4">
                  <RadioGroup
                    name="sharedOwnership"
                    label="Shared ownership"
                    labelCheckMark={false}
                    options={[
                      { _id: 0, value: 1, option: "Yes" },
                      { _id: 1, value: 0, option: "No" },
                    ]}
                    toolTip={null}
                    value={searchFormData.sharedOwnership}
                    onChange={handleRadioChange}
                    // error={errors["sharedOwnership"]}
                    disabled={isLoading}
                  />
                </div>
              ) : null}

              {/* {(searchFormData.buyToLet === 0 &&
                searchFormData.sharedOwnership === 1) ||
              (searchFormData.buyToLet === 1 &&
                searchFormData.sharedOwnership === 0) ? (
                <div></div>
              ) : null} */}

              {/* {searchFormData.buyToLet === 1 &&
              searchFormData.sharedOwnership === 0 ? (
                <div></div>
              ) : null} */}

              {searchFormData.sharedOwnership === 1 ? (
                <>
                  <div className="mb-4">
                    <label
                      htmlFor="sharedOwnershipPercentageRange"
                      className="text-md mb-1 block font-medium text-black dark:text-white"
                    >
                      Share percentage? (%)
                    </label>
                    <div className="flex items-center">
                      <input
                        type="range"
                        name="sharedOwnershipPercentage"
                        id="sharedOwnershipPercentageRange"
                        maxLength="3"
                        min="1"
                        max="100"
                        step="1"
                        value={
                          searchFormData.sharedOwnershipPercentage
                            ? searchFormData.sharedOwnershipPercentage
                            : formData.sharedOwnershipPercentage
                            ? formData.sharedOwnershipPercentage
                            : ""
                        }
                        className="h-3 w-full appearance-none overflow-hidden rounded-lg border border-gray-400 bg-gray-100 focus:shadow-none focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-900"
                        onChange={handleRangeChange}
                      />
                      <input
                        type="number"
                        name="sharedOwnershipPercentage"
                        id="sharedOwnershipPercentageNumber"
                        maxLength={3}
                        min={1}
                        max={100}
                        step={1}
                        value={
                          searchFormData.sharedOwnershipPercentage
                            ? searchFormData.sharedOwnershipPercentage
                            : formData.sharedOwnershipPercentage
                            ? formData.sharedOwnershipPercentage
                            : ""
                        }
                        className="text-md ml-4 block w-[6rem] rounded-md border border-gray-400 bg-white font-bold text-black focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white  "
                        onChange={handleRangeChange}
                      />
                    </div>
                    {incrementButtons(
                      "sharedOwnershipPercentage",
                      1,
                      3,
                      1,
                      100
                    )}
                    {/* {errors.sharedOwnershipPercentage && (
                      <div className="mt-1">
                        <Alert
                          type="danger"
                          text={errors.sharedOwnershipPercentage}
                        />
                      </div>
                    )} */}
                  </div>
                </>
              ) : null}
              {/* 
              {searchFormData.sharedOwnership === 1 ? (
                <>
                  <div></div>
                  <div></div>
                </>
              ) : null} */}

              <div className="mb-4">
                <InputPriceField
                  name="propertyPurchasePrice"
                  label={
                    <span>
                      Property price
                      {/* <br />
                This includes the share */}
                    </span>
                  }
                  labelCheckMark={false}
                  help={
                    <p className="text-md text-white">
                      This can be an actual purchase price or an estimation.
                    </p>
                  }
                  value={searchFormData.propertyPurchasePrice}
                  onChange={handlePriceChange}
                  // error={errors["propertyPurchasePrice"]}
                  disabled={isLoading}
                  // toolTipAlignment="left"
                />
              </div>

              <div className="mb-4">
                <InputPriceField
                  name="deposit"
                  label="Deposit"
                  labelCheckMark={false}
                  help={
                    <p className="text-md text-white">
                      This could be savings, or gifted from family.
                    </p>
                  }
                  // help={
                  //   <span>
                  //     This could be savings, or gifted from family
                  //     {formData.propertyToSell
                  //       ? ". Adjust by adding funds or subtracting existing equity"
                  //       : null}
                  //     .
                  //   </span>
                  // }
                  value={searchFormData.deposit}
                  onChange={handlePriceChange}
                  // error={errors["deposit"]}
                  disabled={isLoading}
                />
                {alerts.deposit && (
                  <div className="mt-1">
                    <Alert type="danger" text={alerts.deposit} />
                  </div>
                )}
              </div>

              <div className="mb-4">
                <InputPriceField
                  name="mortgageLoanAmount"
                  label="Mortgage amount"
                  labelCheckMark={false}
                  help={null}
                  value={searchFormData.mortgageLoanAmount}
                  onChange={handlePriceChange}
                  // error={errors["deposit"]}
                  disabled={isLoading}
                />
              </div>

              <div className="mb-4">
                <InputField
                  name="mortgageTerm"
                  label="Mortgage term (years)"
                  labelCheckMark={false}
                  type="number"
                  maxLength={2}
                  min={1}
                  max={40}
                  help={null}
                  value={searchFormData.mortgageTerm}
                  onChange={handleNumberChange}
                  // error={errors["deposit"]}
                  disabled={isLoading}
                />
              </div>

              {searchFormData.buyToLet === 0 ? (
                <div className="mb-4">
                  <InputPriceField
                    name="income"
                    label="Income"
                    labelCheckMark={false}
                    help={
                      <>
                        <p className="text-md mb-2 text-white">
                          This should be your gross annual income and should
                          include any overtime, bonuses, and shift allowance,
                          etc.
                        </p>
                        <p className="text-md mb-2 text-white">
                          For self employed and contractor incomes this will be
                          your profit as stated on your latest tax circulation
                          (SA302).
                        </p>
                        <p className="text-md mb-0 text-white">
                          For joint purchases this should be the combined total
                          of both incomes.
                        </p>
                      </>
                    }
                    value={searchFormData.income}
                    onChange={handlePriceChange}
                    // error={errors["deposit"]}
                    disabled={isLoading}
                  />
                </div>
              ) : null}

              <div className="mb-4 flex items-center justify-between">
                <p className="text-black dark:text-white">
                  <span className="font-bold">
                    <CountUp
                      end={loanToValue}
                      duration={0.5}
                      decimals={2}
                      // separator={format === "price" ? "," : ""}
                      // prefix={format === "price" ? "£" : ""}
                      preserveValue={true}
                      formattingFn={percentageFormatter}
                    />
                  </span>{" "}
                  Loan to value (LTV)
                </p>
                <Tooltip
                  content={
                    <p className="text-md text-white">
                      Loan to value (LTV) is the ratio of the value of the home
                      you want to buy and the loan you'll need to buy it, shown
                      as a percentage.
                    </p>
                  }
                />
              </div>

              {/* <div className="border border-gray-500"> */}
              <div className="mb-4 flex items-end justify-start">
                <ButtonDefault
                  color="emerald"
                  label="Search"
                  onClick={() => {
                    loadSchemes();
                  }}
                  disabled={isLoading}
                />
              </div>
              {/* </div> */}
            </div>
          ) : null}

          <div id="search-results" className="relative -top-20"></div>

          {!isLoading ? (
            <>
              {showResultsHeader ? (
                <div id="search-results2">
                  <p className="mb-4 text-center text-lg font-bold text-black dark:text-white">
                    Here are our top mortgages that match your criteria
                  </p>

                  <FilterButtons
                    name="rateType"
                    label="Rate type"
                    items={[
                      { _id: 0, label: "All", value: null },
                      { _id: 1, label: "Fixed", value: "Fixed" },
                      { _id: 2, label: "Tracker", value: "Tracker_BBR" },
                      { _id: 3, label: "Variable", value: "Variable" },
                      { _id: 4, label: "Capped", value: "Capped" },
                      { _id: 5, label: "Discount", value: "Discount" },
                    ]}
                    help={
                      <>
                        <p className="text-md mb-2 text-white">
                          A fixed rate mortgage is a type of mortgage where the
                          interest rate on your mortgage stays the same, for the
                          duration of your deal. They can be a useful way to
                          manage your money, as you’ll have a good idea about
                          what you’re going to pay each month.
                        </p>
                        <p className="text-md mb-2 text-white">
                          A tracker mortgage is a type of mortgage deal where
                          the interest rate is equivalent to the Bank of England
                          base interest rate, plus a few percentage points set
                          by your lender.
                        </p>
                        <p className="text-md mb-2 text-white">
                          A standard variable rate (SVR) mortgage has an
                          interest rate that is set by the lender. This rate is
                          not directly linked to the Bank of England, though in
                          the majority of cases it is the primary influence on
                          whether it increases or decreases.
                        </p>
                        <p className="text-md mb-2 text-white">
                          A discount mortgage sees you paying a reduced version
                          of your lender's standard variable rate. The amount of
                          discount is fixed, and the reduction is applied
                          whether the SVR is increased or decreased by the
                          lender.
                        </p>
                        <p className="text-md mb-0 text-white">
                          A capped-rate mortgage is a type of variable rate
                          mortgage that will not rise above a certain rate, also
                          known as a cap.
                        </p>
                      </>
                    }
                    selectedItem={filters.rateType._id}
                    onItemsSelect={handleFilterProducts}
                  />
                  <FilterButtons
                    name="ratePeriod"
                    label="Rate period"
                    items={[
                      { _id: 0, label: "All", value: null },
                      { _id: 1, label: "2 years", value: null },
                      { _id: 2, label: "3 years", value: null },
                      { _id: 3, label: "5 years", value: null },
                      {
                        _id: 4,
                        label: "5 years +",
                        value: null,
                      },
                    ]}
                    selectedItem={filters.ratePeriod._id}
                    onItemsSelect={handleFilterProducts}
                  />

                  {process.env.NODE_ENV === "development" && (
                    <FilterButtons
                      name="rateSteps"
                      label="Rate steps"
                      items={[
                        { _id: 0, label: "All", value: null },
                        { _id: 1, label: "0", value: 0 },
                        { _id: 2, label: "1", value: 1 },
                        { _id: 3, label: "2", value: 2 },
                        { _id: 4, label: "3", value: 3 },
                        { _id: 5, label: "4", value: 4 },
                        { _id: 6, label: "5", value: 5 },
                      ]}
                      selectedItem={filters.rateSteps._id}
                      onItemsSelect={handleFilterProducts}
                    />
                  )}

                  {/* <div className="mb-8 block items-center justify-end sm:flex"> */}
                  <div className="mb-2 mt-4 block sm:flex sm:items-center sm:justify-end sm:gap-2">
                    {/* <p className="mr-2 w-full"></p> */}
                    <p className="whitespace-nowrap text-black dark:text-white">
                      Sort by
                    </p>

                    <select
                      id={"sortProducts"}
                      name={"sortProducts"}
                      value={sortProductsBy.selected}
                      className="text-md my-1 block w-full rounded-lg border-gray-400 bg-white py-2 pl-3 pr-10 font-bold text-black focus:border-indigo-500  focus:outline-none focus:ring-indigo-500 dark:border-gray-600 dark:bg-black dark:text-white sm:w-fit"
                      onChange={handleSortProducts}
                    >
                      {sortProductOptions.map((option) =>
                        option.display ? (
                          <option
                            key={option.order}
                            value={option.value}
                            sort={option.sort}
                          >
                            {option.option}
                          </option>
                        ) : null
                      )}
                    </select>
                  </div>
                </div>
              ) : null}

              {totalProductCount >= 1 ? (
                filteredProductData.slice(0, productsPerPage).map((product) => (
                  <div key={product.schemeID} className="mb-16">
                    <ProductCard
                      product={product}
                      // getLenderLogo={getLenderLogo}
                      handleInfoButton={handleProductInfoButton}
                      handleRemoveProduct={null}
                    />
                  </div>
                ))
              ) : (
                <div className="flex h-48 items-center justify-center">
                  <p className="text-2xl font-bold text-black dark:text-white">
                    Sorry, we can't find any mortgages that match your criteria
                  </p>
                </div>
              )}
            </>
          ) : (
            <div className="flex h-48 flex-col items-center justify-center">
              <div
                className="mb-4 h-16 w-16 animate-spin rounded-full border-8 border-current border-t-gray-400/50 text-gray-400 dark:border-t-gray-600/50 dark:text-gray-600"
                role="status"
                aria-label="loading"
              ></div>

              <p className="animate-pulse text-center text-black dark:text-white">
                We are searching the market for you...
              </p>
            </div>
          )}

          {!isLoading ? (
            <>
              {loadMoreButton ? (
                <div className="mb-16">
                  <button
                    className="inline-flex w-full items-center justify-center whitespace-nowrap rounded-full bg-gray-100 p-4 text-xl font-semibold uppercase leading-none text-black hover:bg-gray-200 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-700"
                    onClick={handleLoadMoreProducts}
                  >
                    Load more mortgages
                  </button>
                </div>
              ) : null}

              <p className="mb-4 text-sm text-black dark:text-white">
                Threshold's mortgage finder is compiled in conjunction with
                Mortgage Brain and compares a comprehensive range of products
                from across the market. These rates are designed to be a guide
                to likely costs; however, you should consider all associated
                fees before applying for a mortgage. The search results compare
                fixed and variable rates for purchasing a new residential home,
                a buy to let or a shared ownership property.
              </p>

              <p className="mb-4 text-sm text-black dark:text-white">
                Rates are constantly changing and those quoted may not be
                available at the time of application. Threshold Mortgage
                Advisers will help you secure a mortgage product that suits your
                circumstances, providing advice on lender and associated costs.
              </p>

              <p className="mb-4  text-sm text-black dark:text-white">
                The above results are based on the{" "}
                {submittedSearchFormData.buyToLet ? "deposit" : "income"} figure
                you've provided. Please be aware that{" "}
                {submittedSearchFormData.buyToLet
                  ? "rental income and financial commitments will need to be"
                  : "credit commitments and credit ratings have not been"}{" "}
                taken into consideration.
              </p>

              <div className="mb-4 border border-black p-2 dark:border-white">
                <p className="text-center uppercase text-black dark:text-white">
                  Think carefully before securing other debts against your
                  property. Your property may be repossessed if you do not keep
                  up repayments on your mortgage
                </p>
              </div>

              <p className="text-center text-sm text-black dark:text-white">
                Some buy to let mortgages are not regulated by the Financial
                Conduct Authority
              </p>
            </>
          ) : null}
        </div>

        <ReviewsBox />
      </div>

      <ScrollToTop
        smooth
        component={<ArrowSmallUp />}
        className="!bottom-[16px] !right-[20px] flex items-center justify-center !rounded-full !bg-gray-100 !text-black !shadow-none hover:!bg-gray-200 dark:!bg-gray-600 dark:!text-white dark:hover:!bg-gray-700"
      />
    </>
  );
};

export default FindMortgagePage;
