import LabelCheckMark from "./LabelCheckMark";
import Tooltip from "../Tooltip";
import Alert from "../Alert";

function RadioGroup({
  name,
  label,
  labelCheckMark = true,
  options,
  layout = "row",
  toolTip,
  optionsTooltip = null,
  helpText,
  optionsHelpText = null,
  value,
  onChange,
  error,
  disabled,
}) {
  const getLabelClasses = () => {
    return !disabled ? "text-black dark:text-white" : "text-gray-500";
  };

  const getButtonClasses = (value, selected, disabled) => {
    return value === selected
      ? !disabled
        ? "bg-indigo-500 border-transparent text-white hover:bg-indigo-600 dark:hover:bg-indigo-400"
        : "bg-gray-200 border-transparent text-gray-500 dark:bg-gray-800 cursor-not-allowed"
      : !disabled
      ? "bg-white dark:bg-black border-gray-400 dark:border-gray-600 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900"
      : "bg-white dark:bg-black border-gray-200 text-gray-500 dark:border-gray-800 cursor-not-allowed";
  };

  const labelCheckMarkValue = value || value === 0 ? true : false;

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          {labelCheckMark && <LabelCheckMark value={labelCheckMarkValue} />}

          <p
            className={`text-md block font-medium ${getLabelClasses()} not-sr-only`}
          >
            {label}
          </p>
        </div>
        {toolTip && <Tooltip content={toolTip} />}
      </div>
      <fieldset className="mb-1">
        <legend className="sr-only">{label}</legend>
        <div className={`flex${layout === "row" ? "" : " flex-col"} gap-4`}>
          {/* // <span
            //   key={option._id}
            //   className={`${optionsTooltip !== null ? "flex" : "w-[8rem]"}`}
            // > */}
          {options.map((option) => (
            <div
              key={option._id}
              className={`flex w-full ${
                layout === "row" ? "max-w-[8rem] flex-col" : "max-w-[21rem]"
              }`}
            >
              {/* <label
                className={`text-md mt-1 flex w-full ${
                  layout === "row" ? "max-w-[8rem]" : "max-w-[16rem]"
                } cursor-pointer items-center justify-center rounded-lg border px-2 py-2 font-bold focus:outline-none sm:flex-1 ${getButtonClasses(
                  value,
                  option.value,
                  disabled
                )}`}
              > */}
              <div className="flex w-full flex-col">
                <label
                  className={`text-md mt-1 flex w-full cursor-pointer items-center justify-center rounded-lg border px-2 py-2 font-bold focus:outline-none sm:flex-1 ${getButtonClasses(
                    value,
                    option.value,
                    disabled
                  )}`}
                >
                  <input
                    type="radio"
                    name={name}
                    value={option.value}
                    className="sr-only"
                    aria-labelledby={`${name}-${option._id}`}
                    // checked={value === option._id}
                    onChange={onChange}
                  />
                  <p id={`${name}-${option._id}`}>{option.option}</p>
                </label>

                {/* {optionsHelpText &&
                  optionsHelpText.map(
                    (item, index) =>
                      item.value === option.value && (
                        <p className="text-sm text-black dark:text-white">
                          {item.content}
                        </p>
                      )
                  )} */}
              </div>

              {option.content ? (
                <span className="ml-2">
                  <Tooltip content={option.content} />
                </span>
              ) : (
                <span className="ml-8"></span>
              )}

              {/* {optionsTooltip &&
                optionsTooltip.map(
                  (item, index) =>
                    item.value === option.value && (
                      <span key={index} className="ml-2">
                        <Tooltip content={item.content} />
                      </span>
                    )
                )} */}
            </div>
          ))}
        </div>
      </fieldset>

      {error && <Alert type="danger" text={error} />}
    </>
  );
}

export default RadioGroup;
