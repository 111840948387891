import React, { Fragment } from "react";

function LogoDefault(props) {
  return (
    <Fragment>
      <svg
        version="1.1"
        id="mortgagepro-lite-logo"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height="20"
        viewBox="0 0 320 38.4"
        style={{ enableBackground: "new 0 0 320 38.4" }}
        xmlSpace="preserve"
        // className="w-full pr-4"
      >
        <g>
          <path
            className="fill-black dark:fill-white"
            d="M0,8.7h6.7v3.1h0.1c1.4-2.3,3.5-3.8,6.6-3.8c2.9,0,5.1,1.5,6.2,3.9h0.1c1.7-2.7,4.1-3.9,6.9-3.9c5,0,7.5,3.4,7.5,8.3v14.3
		h-7v-13c0-2.4-1-3.7-3.1-3.7c-2.2,0-3.5,1.8-3.5,4.5v12.3h-7v-13c0-2.4-1-3.7-3.1-3.7c-2.2,0-3.5,1.8-3.5,4.5v12.3H0V8.7z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M36.3,19.7C36.3,13.1,41,8.1,48,8.1c7.1,0,11.7,5,11.7,11.6S55.1,31.3,48,31.3C41,31.3,36.3,26.3,36.3,19.7z M52.6,19.7
		c0-4-1.6-6.7-4.7-6.7c-3,0-4.6,2.7-4.6,6.7s1.6,6.6,4.6,6.6C51,26.4,52.6,23.7,52.6,19.7z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M62.1,8.7h6.7v3.4h0.1c1.5-2.6,3.3-3.8,6-3.8c0.6,0,1.1,0,1.4,0.2v6h-0.2c-4.3-0.6-7,1.5-7,6.3v9.9h-7V8.7z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M80.6,24.9V13.1h-2.9V8.7h2.9V1.8h6.8v6.9h4v4.4h-4v10.3c0,1.7,0.9,2.1,2.4,2.1c0.6,0,1.3,0,1.5,0v5.1
		c-0.6,0.2-1.9,0.3-3.8,0.3C83.6,30.9,80.6,29.6,80.6,24.9z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M93.2,31.2h6.8c0.4,1.2,1.5,2.1,3.9,2.1c3,0,4.2-1.5,4.2-4.2v-2.2h-0.2c-1.3,1.5-3,2.5-5.6,2.5c-4.9,0-9.5-3.7-9.5-10.4
		c0-6.4,3.8-11,9.2-11c2.9,0,4.9,1.2,6.2,3h0.1V8.7h6.7v19.8c0,3.3-1.2,5.7-2.9,7.3c-1.9,1.7-4.8,2.4-8.1,2.4
		C97.9,38.2,93.9,35.8,93.2,31.2z M108.4,18.8c0-3-1.5-5.4-4.5-5.4c-2.6,0-4.3,2.1-4.3,5.5c0,3.3,1.7,5.4,4.3,5.4
		C107,24.3,108.4,21.9,108.4,18.8z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M117.3,24.5c0-4.8,3.9-6.3,8.4-6.9c4.1-0.5,5.6-0.9,5.6-2.4c0-1.4-0.9-2.4-3-2.4c-2.2,0-3.3,1-3.6,2.8h-6.4
		c0.2-4.1,3.3-7.5,9.9-7.5c3.3,0,5.5,0.6,7.1,1.8c1.9,1.4,2.8,3.5,2.8,6.3v11.1c0,1.8,0.2,2.7,0.8,3.1v0.3h-6.8
		c-0.3-0.5-0.6-1.3-0.7-2.4h-0.1c-1.3,1.8-3.2,3-6.5,3C120.4,31.2,117.3,28.9,117.3,24.5z M131.4,22.8v-2.6
		c-0.9,0.5-2.2,0.8-3.6,1.2c-2.7,0.6-3.8,1.3-3.8,3c0,1.8,1.3,2.4,3,2.4C129.6,26.8,131.4,25.2,131.4,22.8z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M141,31.2h6.8c0.4,1.2,1.5,2.1,3.9,2.1c3,0,4.2-1.5,4.2-4.2v-2.2h-0.2c-1.3,1.5-3,2.5-5.6,2.5c-4.9,0-9.5-3.7-9.5-10.4
		c0-6.4,3.8-11,9.2-11c2.9,0,4.9,1.2,6.2,3h0.1V8.7h6.7v19.8c0,3.3-1.2,5.7-2.9,7.3c-1.9,1.7-4.8,2.4-8.1,2.4
		C145.6,38.2,141.7,35.8,141,31.2z M156.1,18.8c0-3-1.5-5.4-4.5-5.4c-2.6,0-4.3,2.1-4.3,5.5c0,3.3,1.7,5.4,4.3,5.4
		C154.7,24.3,156.1,21.9,156.1,18.8z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M165,19.6c0-6.6,4.6-11.6,11.3-11.6c3.1,0,5.6,1,7.5,2.7c2.6,2.4,3.9,6.2,3.9,10.7h-15.8c0.4,3,2.1,4.8,4.9,4.8
		c1.8,0,3-0.8,3.6-2.2h6.8c-0.5,2-1.8,3.9-3.7,5.2c-1.8,1.3-4.1,2-6.9,2C169.5,31.3,165,26.3,165,19.6z M180.6,17.3
		c-0.3-2.6-1.9-4.3-4.2-4.3c-2.7,0-4,1.7-4.5,4.3H180.6z"
          />
          <path
            className="stroke-black dark:stroke-white"
            style={{ fill: "none", strokeMiterlimit: "10" }}
            d="M190,8.7h6.7v2.7h0.1c1.5-2,3.6-3.3,6.6-3.3c5.8,0,9.4,5,9.4,11.6c0,7.1-3.8,11.7-9.6,11.7
		c-2.8,0-4.8-1.1-6.2-3H197v9.5h-7V8.7z M205.8,19.9c0-3.8-1.4-6.3-4.4-6.3c-3,0-4.6,2.7-4.6,6.3s1.7,6,4.7,6
		C204.2,25.9,205.8,23.7,205.8,19.9z"
          />
          <path
            className="stroke-black dark:stroke-white"
            style={{ fill: "none", strokeMiterlimit: "10" }}
            d="M215.2,8.7h6.7v3.4h0.1c1.5-2.6,3.3-3.8,6-3.8c0.6,0,1.1,0,1.4,0.2v6h-0.2c-4.3-0.6-7,1.5-7,6.3v9.9h-7V8.7z"
          />
          <path
            className="stroke-black dark:stroke-white"
            style={{ fill: "none", strokeMiterlimit: "10" }}
            d="M230.2,19.7c0-6.6,4.7-11.6,11.7-11.6c7.1,0,11.7,5,11.7,11.6S249,31.3,242,31.3
		C234.9,31.3,230.2,26.3,230.2,19.7z M246.6,19.7c0-4-1.6-6.7-4.7-6.7c-3,0-4.6,2.7-4.6,6.7s1.6,6.6,4.6,6.6
		C245,26.4,246.6,23.7,246.6,19.7z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M262.7,0h7.1v30.7h-7.1V0z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M273.2,0h7v5.7h-7V0z M273.2,8.7h7v22h-7V8.7z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M285.2,24.9V13.1h-2.9V8.7h2.9V1.8h6.8v6.9h4v4.4h-4v10.3c0,1.7,0.9,2.1,2.4,2.1c0.6,0,1.3,0,1.5,0v5.1
		c-0.6,0.2-1.9,0.3-3.8,0.3C288.1,30.9,285.2,29.6,285.2,24.9z"
          />
          <path
            className="fill-black dark:fill-white"
            d="M297.4,19.6c0-6.6,4.6-11.6,11.3-11.6c3.1,0,5.6,1,7.5,2.7c2.6,2.4,3.9,6.2,3.9,10.7h-15.8c0.4,3,2.1,4.8,4.9,4.8
		c1.8,0,3-0.8,3.6-2.2h6.8c-0.5,2-1.8,3.9-3.7,5.2c-1.8,1.3-4.1,2-6.9,2C301.8,31.3,297.4,26.3,297.4,19.6z M312.9,17.3
		c-0.3-2.6-1.9-4.3-4.2-4.3c-2.7,0-4,1.7-4.5,4.3H312.9z"
          />
        </g>
      </svg>
    </Fragment>
  );
}

export default LogoDefault;
