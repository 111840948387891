import percentage from "calculate-percentages";
import { priceFormatter } from "./PriceFormatter";
import settings from "../data/settings.json";

export const depositCheck = (
  calculatedPropertyPurchasePrice,
  deposit,
  buyToLet,
  helpToBuy,
  firstHomes,
  firstHomesGreaterLondon,
  propertyRegionDetails
) => {
  // borrow amount = x5 Income - result[0]
  // divide by .95
  const percent = buyToLet === 1 ? 20 : 5;

  const requiredDepositAmount = percentage.of(
    percent,
    calculatedPropertyPurchasePrice
  );

  const getPriceCap = () => {
    return firstHomesGreaterLondon
      ? settings.firstHomes.priceCapLondon
      : settings.firstHomes.priceCap;
  };

  return buyToLet === 1
    ? [
        "info",
        <span>
          Based on your calculated property purchase price, your deposit amount
          meets the minimum requirement of{" "}
          <span className="font-bold">20%</span> (
          {priceFormatter(requiredDepositAmount)}) for some buy to let mortgage
          lenders.
        </span>,
        "white",
      ]
    : firstHomes && deposit > getPriceCap()
    ? [
        "warning",
        <span>
          Your deposit is above the maximum property purchase price for the{" "}
          <span className="font-bold">First Homes</span> scheme.
          <br />
          Are you sure you require the{" "}
          <span className="font-bold">First Homes</span> scheme?
        </span>,
        "white",
      ]
    : requiredDepositAmount > deposit
    ? [
        "warning",
        <span>
          A minimum of <span className="font-bold">5%</span> deposit is required{" "}
          {helpToBuy === 1 ? (
            <span>
              for the <span className="font-bold">Help to Buy</span> scheme
            </span>
          ) : (
            <span>for most mortgage lenders</span>
          )}
          .<br />
          Based on your {firstHomes ? "discounted" : ""} calculated property
          purchase price a minimum deposit of{" "}
          <span className="font-bold">
            {priceFormatter(requiredDepositAmount)}
          </span>{" "}
          is required.
        </span>,
        "white",
      ]
    : [
        "success",
        <span>
          Based on your {firstHomes ? "discounted" : ""} calculated property
          purchase price, your deposit amount meets the minimum requirement of{" "}
          <span className="font-bold">5%</span> (
          {priceFormatter(requiredDepositAmount)}){" "}
          {helpToBuy === 1 ? (
            <span>
              for the <span className="font-bold">Help to Buy</span> scheme
            </span>
          ) : (
            <span>for some mortgage lenders</span>
          )}
          .
        </span>,
        "white",
      ];
};
