import React, { useContext, useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import Title from "../components/Title";
import ResultsDrawer from "../components/ResultsDrawer";
import FormMain from "../components/FormMain";
import FindMyMortgage from "../components/widgets/FindMyMortgage";
import GetInTouch from "../components/widgets/GetInTouch";
import Results from "../components/Results.jsx";
import AppContext from "../Context/AppContext";
import AboutThreshold from "../components/widgets/AboutThreshold";
import ReviewsBox from "../components/ReviewsBox";

import removeScrollLockClasses from "../utils/removeScrollLockClasses";

function CalculationsPage(props) {
  const { domain, referral, showResults, setShowResults } =
    useContext(AppContext);

  const [openResultsDrawer, setOpenResultsDrawer] = useState(false);
  const [disableResultsBar, setDisableResultsBar] = useState(false);

  useEffect(() => {
    removeScrollLockClasses();
  }, []);

  useEffect(() => {
    disableResultsBar &&
      setTimeout(() => {
        setDisableResultsBar(!disableResultsBar);
      }, 1000);
  }, [disableResultsBar]);

  useEffect(() => {
    if (showResults && window.innerWidth < 768) {
      setOpenResultsDrawer(!openResultsDrawer);
      preventBodyScroll(!openResultsDrawer);
      setDisableResultsBar(!disableResultsBar);
    }
  }, [showResults]);

  const handleResultsBarClick = () => {
    if (showResults && !disableResultsBar) {
      setOpenResultsDrawer(!openResultsDrawer);
      preventBodyScroll(!openResultsDrawer);
      setShowResults(!showResults);
      setDisableResultsBar(!disableResultsBar);
    }
  };

  const preventBodyScroll = (isDrawerOpen) => {
    //https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/

    const body = document.getElementsByTagName("body")[0];

    if (isDrawerOpen) {
      setTimeout(() => {
        body.classList.add("fixed");
        document.body.style.top = `-${window.scrollY}px`;
      }, 1000);

      let width = true;
      window.onresize = () => {
        if (window.innerWidth >= 768 && width) {
          removeScrollLockClasses();
          width = false;
        }
      };
    } else {
      const scrollY = document.body.style.top;
      body.removeAttribute("class");
      body.removeAttribute("style");
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  };

  ///// Still  working on
  // let resetShowResults = false;
  // let resetScrollLockClasses = false;
  // //const currentWindowWidth = window.innerWidth;
  // window.onresize = () => {
  //   if (window.innerWidth < 768 && !resetShowResults) {
  //     // console.log("RESET!");
  //     setShowResults(false);
  //     setOpenResultsDrawer(false);
  //     resetShowResults = true;
  //   }
  //   if (
  //     // currentWindowWidth < 768 &&
  //     window.innerWidth > 768 &&
  //     !resetScrollLockClasses
  //   ) {
  //     removeScrollLockClasses();

  //     // console.log("RESET S-LOCK!");

  //     resetScrollLockClasses = true;
  //   }
  // };

  return (
    <>
      <div className="mx-auto mb-32 mt-12 max-w-5xl px-4 md:mb-16 md:px-8">
        <div className="mb-8">
          <Title title="Find my budget" stage="1" />
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="mx-auto w-full max-w-lg">
            <div className="frost mb-8 rounded-lg border border-gray-200 bg-white/50 p-4 pb-0 shadow-xl shadow-purple-500/25 dark:border-gray-800 dark:bg-black/50 dark:shadow-purple-500/50">
              <FormMain />
            </div>

            {/* <FindMyMortgage /> */}

            {domain === "thr" && referral && <AboutThreshold />}

            {/* <GetInTouch /> */}

            <ReviewsBox />
          </div>

          <div className="hidden md:block">
            <div className="mx-auto w-full max-w-sm">
              <Results />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`fixed bottom-0 z-40 flex h-16 w-full items-center justify-around bg-gradient-to-tl from-purple-500 via-indigo-500 to-sky-500 text-black shadow-md shadow-purple-500/25 transition-all duration-1000 dark:text-white dark:shadow-purple-500/50 md:hidden ${
          showResults
            ? "visible cursor-pointer opacity-100"
            : "invisible cursor-default opacity-0"
        }`}
        onClick={handleResultsBarClick}
      >
        {disableResultsBar && (
          <div className="pointer-events-none absolute inset-0 z-50"></div>
        )}
        <button
          type="button"
          className={`inline-flex items-center rounded-full border border-white px-4 py-2 text-sm font-medium uppercase text-white   ${
            showResults
              ? disableResultsBar
                ? "cursor-pointer"
                : "cursor-pointer hover:bg-black/25 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              : "cursor-default"
          }`}
        >
          {/* {openResultsDrawer ? "Hide results" : "Show results"} */}
          Hide results
        </button>
        <div
          className={`rounded-full p-2 ${
            !disableResultsBar && "hover:bg-black/25"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // className={`h-6 w-6 text-white ${
            //   openResultsDrawer ? "rotate-180" : "rotate-0"
            // }`}
            className="h-6 w-6 rotate-180 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 11l7-7 7 7M5 19l7-7 7 7"
            />
          </svg>
        </div>
      </div>

      <ResultsDrawer openResultsDrawer={openResultsDrawer} />
    </>
  );
}

export default CalculationsPage;
