import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  return (
    <Fragment>
      {location.pathname !== "/" ? (
        <footer className="md:w-ful hidden md:flex md:py-4">
          <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
            <p className="mb-2 text-center text-xs text-black dark:text-white">
              Threshold Financial Services Limited is an appointed
              representative of The Openwork Partnership, a trading style of
              Openwork Limited which is authorised and regulated by the
              Financial&nbsp;Conduct&nbsp;Authority
            </p>
            <p className="text-center text-xs text-black dark:text-white">
              Approved by The Openwork Partnership on 10/08/2023
            </p>
          </div>
        </footer>
      ) : null}
    </Fragment>
  );
}
export default Footer;
