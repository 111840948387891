import React, { useContext } from "react";
import LogoDefault from "./svg/LogoDefault";
import LogoTHR from "./svg/LogoTHR";
import AppContext from "../Context/AppContext";

function Logo(props) {
  const { domain } = useContext(AppContext);

  const getLogo = (domain) => {
    if (domain === "thr") return <LogoTHR />;

    return <LogoDefault />;
  };

  return getLogo(domain);
}

export default Logo;
