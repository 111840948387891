export const calculatePayableOverTerm = (product) => {
  const initialMonthlyPaymentTotal =
    product.initialMonthlyPayment.toFixed(2) * product.rateControlPeriod;

  const finalMonthlyPaymentTotal =
    (product.term * 12 - product.rateControlPeriod) *
    product.finalMonthlyPayment.toFixed(2);

  return parseFloat(initialMonthlyPaymentTotal + finalMonthlyPaymentTotal);
};

export const calculatePayableOverTermIncludeFees = (product) => {
  const initialMonthlyPaymentTotal =
    product.initialMonthlyPayment.toFixed(2) * product.rateControlPeriod;

  const finalMonthlyPaymentTotal =
    (product.term * 12 - product.rateControlPeriod) *
    product.finalMonthlyPayment.toFixed(2);

  const feesTotal = product.totalFees + product.finalFee - product.cashback;

  return parseFloat(
    initialMonthlyPaymentTotal + finalMonthlyPaymentTotal + feesTotal
  );
};
