import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import ButtonThemeSelect from "./ButtonThemeSelect";
import Navigation from "./Navigation";

function Header(props) {
  let navigate = useNavigate();

  return (
    <>
      <header className="fixed z-40 mb-8 w-full bg-white shadow-md shadow-purple-500/25 dark:bg-black dark:shadow-purple-500/50">
        <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
          <div className="flex h-12 items-center justify-between">
            <Link to="/">
              <Logo />
            </Link>

            <div className="flex items-center justify-around gap-4 divide-x divide-gray-300 dark:divide-gray-700">
              <div>
                {/* <button
                type="button"
                className="inline-flex items-center whitespace-nowrap rounded-full bg-sky-500 px-2 py-2 text-xs uppercase leading-none text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 dark:hover:bg-emerald-400 sm:px-3 sm:text-sm"
                onClick={() => navigate("/find-mortgage")}
              >
                <span className="sm:inline-block">Find a mortgage</span>
              </button> */}
                <button
                  type="button"
                  className="inline-flex items-center whitespace-nowrap rounded-full bg-emerald-500 px-2 py-2 text-xs uppercase leading-none text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 dark:hover:bg-emerald-400 sm:px-3 sm:text-sm"
                  onClick={() => navigate("/contact")}
                >
                  <span className="hidden xs:block">Get in touch</span>
                  <span className="block xs:hidden">Contact</span>
                </button>
              </div>

              <ButtonThemeSelect />
            </div>
          </div>
        </div>
      </header>
      {/* <Navigation /> */}
    </>
  );
}

export default Header;
